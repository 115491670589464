import React, { useState } from 'react';
import { Fade, Backdrop, Modal, Box, Typography, TextField, Button, Checkbox, FormControlLabel } from '@mui/material';
import { createProduct } from '../../../api';
import { toast } from 'react-toastify';

const CreateProductModal = ({ open, handleClose, companyId }) => {
  const [formData, setFormData] = useState({
    sku: '',
    name: '',
    price: '',
    sellableQuantity: '',
    description: '',
    isVariationParent: false,
    isBundle: false,
    companyId: companyId || 0,
    imageUrls: [],
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const productData = {
      sku: formData.sku,
      name: formData.name,
      price: parseFloat(formData.price),
      sellableQuantity: parseInt(formData.sellableQuantity, 10),
      description: formData.description,
      isVariationParent: formData.isVariationParent,
      isBundle: formData.isBundle,
      companyId: formData.companyId,
      imageUrls: formData.imageUrls,
    };

    try {
      await createProduct(productData);
      toast.success('Product created successfully');
      handleClose();
    } catch (error) {
      console.error('Error from backend:', error);
      toast.error(error.error || 'There was an error creating the product');
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Create Product
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="SKU"
              name="sku"
              value={formData.sku}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Price"
              name="price"
              value={formData.price}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              type="number"
              inputProps={{ min: 0, step: "0.01" }} // Allow cents
            />
            <TextField
              label="Quantity"
              name="sellableQuantity"
              value={formData.sellableQuantity}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              type="number" // ensures numeric input
              inputProps={{ min: 0 }}
            />
            <TextField
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              fullWidth
              margin="normal"
              multiline
              rows={4}
            />
            <Button type="submit" variant="contained" color="primary" style={{ marginTop: '16px' }}>
              Create Product
            </Button>
          </form>
        </Box>
      </Fade>
    </Modal>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default CreateProductModal;
