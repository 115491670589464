import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { styled } from '@mui/system';
import { Fade } from 'react-awesome-reveal';
import { HashLink as Link } from 'react-router-hash-link';
import bannerGroup from '../assets/images/banner/banner-group.png';
import bannerGroup2 from '../assets/images/banner/banner-group2.png';
import bannerFrame2 from '../assets/images/banner/banner_frame2.png';
import heroAreaImage from '../assets/images/hero-area.svg';

const HeroContainer = styled('div')({
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: `url(${heroAreaImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});

const HeroContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: '1200px',
  zIndex: 2,
  padding: '20px',
});

const HeroText = styled('div')({
  maxWidth: '60%',
  zIndex: 2,
});

const BannerImageContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
});

const HeroImage = styled('img')({
  width: '100%',
  maxWidth: '500px',
  height: 'auto',
});

const FrameImage = styled('img')({
  position: 'absolute',
  right: '10%',
  bottom: '10%',
  width: '150px',
});

const HeroSection = () => (
  <HeroContainer>
    <Container maxWidth="lg">
      <HeroContent>
        <HeroText>
          <Fade bottom>
            <Typography variant="h2" color="textPrimary" gutterBottom>
              StackrHub
            </Typography>
          </Fade>
          <Fade bottom delay={100}>
            <Typography variant="h5" color="textSecondary" paragraph>
              E-Commerce Marketplace Management Tool
            </Typography>
          </Fade>
          <Fade bottom delay={200}>
            <Typography variant="body1" color="textSecondary" paragraph>
              Manage your e-commerce listings, orders, and inventory. Automate your daily processes.
            </Typography>
          </Fade>
          <Fade bottom delay={300}>
            <Box mt={2}>
              <Link to="/register" style={{ textDecoration: 'none' }}>
                <Button variant="contained" color="primary" style={{ marginRight: '16px' }}>
                  Start 7 Day Free Trial
                </Button>
              </Link>
              <Button variant="outlined" color="primary">
                Learn More
              </Button>
            </Box>
          </Fade>
        </HeroText>
        <BannerImageContainer>
          <Fade left delay={600}>
            <HeroImage src={bannerGroup} alt="Banner Group" />
          </Fade>
          <Fade right delay={400}>
            <HeroImage src={bannerGroup2} alt="Banner Group 2" style={{ position: 'absolute', top: '10%', left: '15%' }} />
          </Fade>
          <FrameImage src={bannerFrame2} alt="Frame" />
        </BannerImageContainer>
      </HeroContent>
    </Container>
  </HeroContainer>
);

export default HeroSection;
