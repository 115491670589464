import React from 'react';
import { Container, Typography, Grid } from '@mui/material';
import ServiceCard from './ServiceCard';

const services = [
  {
    title: 'Multi-Marketplace Management',
    description: 'Easily manage listings, orders, and inventory across platforms from one dashboard.',
    icon: 'FiGlobe'
  },
  {
    title: 'Inventory & Order Control',
    description: 'Stay on top of stock levels, track movement, and avoid overselling with real-time updates.',
    icon: 'FiLayers'
  },
  {
    title: 'Integration with Essential Tools',
    description: 'Connect with shipping, accounting, and other key e-commerce tools for streamlined operations.',
    icon: 'FiLink'
  },
  {
    title: 'Real-Time Synchronization',
    description: 'Ensure accurate data across all channels to avoid overselling and manage inventory precisely.',
    icon: 'FiRefreshCw'
  },
];

const ServicesSection = () => (
  <Container maxWidth="lg" id="services" component="section" style={{ padding: '80px 0' }}>
    <Typography component="h2" variant="h4" align="center" color="textPrimary" gutterBottom>
      Digitally Transform Your Workplace
    </Typography>
    <Typography variant="body1" align="center" color="textSecondary" paragraph>
      Boost productivity and streamline operations with our powerful suite of tools tailored to enhance your digital workflow.
    </Typography>
    <Grid container spacing={3} justifyContent="center" alignItems="stretch">
      {services.map((service) => (
        <Grid item key={service.title} xs={12} sm={6} md={3}>
          <ServiceCard service={service} />
        </Grid>
      ))}
    </Grid>
  </Container>
);

export default ServicesSection;
