import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, Box, Stepper, Step, StepLabel, Paper, MenuItem, FormControl, InputLabel, Select, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { styled } from '@mui/system';
import { register, getPaypalClientId } from '../api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { loadScript } from '@paypal/paypal-js';
import Header from '../components/Header';

const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
});

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  borderRadius: theme.shape.borderRadius,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

const steps = ['User Info', 'Company Info', 'Subscription Info'];

const states = [
  'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
  'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts',
  'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
  'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania',
  'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington',
  'West Virginia', 'Wisconsin', 'Wyoming'
];

const Register = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [paypalClientId, setPaypalClientId] = useState('');
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    userInfo: { firstName: '', lastName: '', email: '', password: '' },
    companyInfo: { companyName: '', phoneNumber: '', street1: '', street2: '', city: '', state: '', zipCode: '' },
    subscriptionInfo: {
      users: 1,
      products: 0,
      orders: 0,
      integrations: 0,
      support: 'help_center',
      paymentMethod: ''
    },
  });

  useEffect(() => {
    // Check for subscription options in local storage
    const savedOptions = localStorage.getItem('subscriptionOptions');
    if (savedOptions) {
      setFormData((prevData) => ({
        ...prevData,
        subscriptionInfo: {
          ...prevData.subscriptionInfo,
          ...JSON.parse(savedOptions),
        },
      }));
    }

    // Fetch PayPal client ID from the backend
    getPaypalClientId()
      .then((data) => setPaypalClientId(data))
      .catch((error) => console.error('Error fetching PayPal Client ID:', error));
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (section, field) => (event) => {
    setFormData({
      ...formData,
      [section]: {
        ...formData[section],
        [field]: event.target.value,
      },
    });
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    if (formData.subscriptionInfo.paymentMethod === 'paypal') {
      loadScript({ 'client-id': paypalClientId }).then((paypal) => {
        paypal.Buttons({
          createSubscription: function (data, actions) {
            return actions.subscription.create({
              'plan_id': formData.subscriptionInfo.plan
            });
          },
          onApprove: function (data, actions) {
            return actions.subscription.get().then(function (details) {
              const subscriptionData = {
                ...formData,
                subscriptionId: details.id,
              };
              register(subscriptionData).then(() => {
                toast.success('Registration successful');
                navigate('/dashboard');
              }).catch((error) => {
                toast.error(error.error || 'There was an error registering');
              });
            });
          }
        }).render('#paypal-button-container');
      });
    } else {
      try {
        await register(formData);
        toast.success('Registration successful');
        navigate('/dashboard');
      } catch (error) {
        toast.error(error.error || 'There was an error registering');
      }
    }
  };


  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <StyledTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="First Name"
              value={formData.userInfo.firstName}
              onChange={handleChange('userInfo', 'firstName')}
            />
            <StyledTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Last Name"
              value={formData.userInfo.lastName}
              onChange={handleChange('userInfo', 'lastName')}
            />
            <StyledTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Email"
              type="email"
              value={formData.userInfo.email}
              onChange={handleChange('userInfo', 'email')}
            />
            <StyledTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Password"
              type="password"
              value={formData.userInfo.password}
              onChange={handleChange('userInfo', 'password')}
            />
          </Box>
        );
      case 1:
        return (
          <Box>
            <StyledTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Company Name"
              value={formData.companyInfo.companyName}
              onChange={handleChange('companyInfo', 'companyName')}
            />
            <StyledTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Phone Number"
              value={formData.companyInfo.phoneNumber}
              onChange={handleChange('companyInfo', 'phoneNumber')}
            />
            <StyledTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Street Address 1"
              value={formData.companyInfo.street1}
              onChange={handleChange('companyInfo', 'street1')}
            />
            <StyledTextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Street Address 2"
              value={formData.companyInfo.street2}
              onChange={handleChange('companyInfo', 'street2')}
            />
            <StyledTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="City"
              value={formData.companyInfo.city}
              onChange={handleChange('companyInfo', 'city')}
            />
            <StyledTextField
              select
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="State"
              value={formData.companyInfo.state}
              onChange={handleChange('companyInfo', 'state')}
            >
              {states.map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </StyledTextField>
            <StyledTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Zip Code"
              value={formData.companyInfo.zipCode}
              onChange={handleChange('companyInfo', 'zipCode')}
            />
          </Box>
        );
      case 2:
        return (
          <Box>
            <FormControl fullWidth variant="outlined" margin="normal" required>
              <InputLabel>Users</InputLabel>
              <Select
                value={formData.subscriptionInfo.users}
                onChange={handleChange('subscriptionInfo', 'users')}
                label="Users"
              >
                <MenuItem value={1}>1-2</MenuItem>
                <MenuItem value={3}>3-6</MenuItem>
                <MenuItem value={7}>7-10</MenuItem>
                <MenuItem value={11}>11+</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth variant="outlined" margin="normal" required>
              <InputLabel>Product Count</InputLabel>
              <Select
                value={formData.subscriptionInfo.products}
                onChange={handleChange('subscriptionInfo', 'products')}
                label="Product Count"
              >
                <MenuItem value={0}>0-500</MenuItem>
                <MenuItem value={501}>501-1000</MenuItem>
                <MenuItem value={1001}>1001-2000</MenuItem>
                <MenuItem value={2001}>2001+</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth variant="outlined" margin="normal" required>
              <InputLabel>Monthly Orders</InputLabel>
              <Select
                value={formData.subscriptionInfo.orders}
                onChange={handleChange('subscriptionInfo', 'orders')}
                label="Monthly Orders"
              >
                <MenuItem value={0}>0-500</MenuItem>
                <MenuItem value={501}>501-1000</MenuItem>
                <MenuItem value={1001}>1001-2000</MenuItem>
                <MenuItem value={2001}>2001+</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth variant="outlined" margin="normal" required>
              <InputLabel>Integrations</InputLabel>
              <Select
                value={formData.subscriptionInfo.integrations}
                onChange={handleChange('subscriptionInfo', 'integrations')}
                label="Integrations"
              >
                <MenuItem value={0}>0-3</MenuItem>
                <MenuItem value={4}>4-6</MenuItem>
                <MenuItem value={7}>7-10</MenuItem>
                <MenuItem value={11}>11+</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth variant="outlined" margin="normal" required>
              <InputLabel>Support Level</InputLabel>
              <Select
                value={formData.subscriptionInfo.support}
                onChange={handleChange('subscriptionInfo', 'support')}
                label="Support Level"
              >
                <MenuItem value="help_center">Help Center & Email Support</MenuItem>
                <MenuItem value="personal_manager">Personal Account Manager</MenuItem>
              </Select>
            </FormControl>
          </Box>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <React.Fragment>
      <Header />
      <StyledContainer maxWidth="sm">
        <StyledBox component={Paper}>
          <Typography variant="h5" component="h1" gutterBottom>
            Register
          </Typography>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <form>
            {getStepContent(activeStep)}
            <Box mt={2}>
              {activeStep !== 0 && (
                <Button onClick={handleBack} style={{ marginRight: '8px' }}>
                  Back
                </Button>
              )}
              {activeStep === steps.length - 1 ? (
                <Button variant="contained" color="primary" onClick={handleRegister}>
                  Register
                </Button>
              ) : (
                <Button variant="contained" color="primary" onClick={handleNext}>
                  Next
                </Button>
              )}
            </Box>
          </form>
        </StyledBox>
        <ToastContainer />
      </StyledContainer>
    </React.Fragment>
  );
};

export default Register;
