import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { Fade } from 'react-awesome-reveal';
import { HashLink as Link } from 'react-router-hash-link';
import aboutImage from '../assets/images/banner/about-image.png';
import aboutImage1 from '../assets/images/banner/about-img_1.png';

const AboutSection = () => (
  <Box component="section" id="about" style={{ backgroundColor: '#f7f7f7', padding: '80px 0' }}>
    <Container maxWidth="lg">
      <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }} alignItems="center">

        {/* Image Area */}
        <Fade left>
          <Box flex="1" position="relative" display="flex" justifyContent="center" mb={{ xs: 4, lg: 0 }}>
            <Box position="relative">
              <img src={aboutImage} alt="About" style={{ maxWidth: '100%', borderRadius: '8px' }} />
              <img
                src={aboutImage1}
                alt="About overlay"
                style={{
                  position: 'absolute',
                  top: '10%',
                  left: '10%',
                  width: '60px',
                  height: '60px',
                  borderRadius: '50%',
                  animation: 'pulse 2s infinite'
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  top: '15%',
                  left: '15%',
                  width: '80px',
                  height: '80px',
                  borderRadius: '50%',
                  backgroundColor: '#702963',
                  opacity: 0.2,
                  animation: 'pulse 2s infinite'
                }}
              ></div>
            </Box>
          </Box>
        </Fade>

        {/* Text Area */}
        <Fade right>
          <Box flex="1" ml={{ lg: 6 }}>
            <Typography component="h2" variant="h4" color="textPrimary" gutterBottom>
              Seamlessly Connect with Leading E-commerce Platforms
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
              Effortlessly integrate with platforms like eBay, Shopify, and Etsy to manage your products, inventory, and orders all from one place. Enhance your workflow and drive growth with the power of StackrHub.
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
              Founded on the principles of innovation and customer-centricity, StackrHub offers a comprehensive suite of solutions designed to meet the unique challenges of modern e-commerce. Our platform integrates seamlessly with major marketplaces like eBay, Etsy, Shopify, and more, ensuring that your business stays connected and competitive.
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
              We pride ourselves on our commitment to quality, reliability, and continuous improvement. Our team of experts works tirelessly to deliver cutting-edge features and exceptional support, helping our customers navigate the complexities of online retail with confidence.
            </Typography>
            <Box mt={3}>
              <Link smooth to="/register">
                <Button variant="contained" color="primary">
                  Get Started
                </Button>
              </Link>
            </Box>
          </Box>
        </Fade>
      </Box>
    </Container>
  </Box>
);

export default AboutSection;
