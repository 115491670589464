import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import { FiPackage, FiShoppingCart, FiRefreshCw, FiBarChart2, FiLink, FiFolderPlus } from 'react-icons/fi';
import { useTheme } from '@mui/material/styles';

const CardContainer = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  textAlign: 'center',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: theme.shadows[4],
  },
}));

const icons = {
  FiPackage: FiPackage,
  FiShoppingCart: FiShoppingCart,
  FiRefreshCw: FiRefreshCw,
  FiBarChart2: FiBarChart2,
  FiLink: FiLink,
  FiFolderPlus: FiFolderPlus,
};

const ServiceCard = ({ service }) => {
  const theme = useTheme();
  const IconComponent = icons[service.icon] || FiPackage; // Default to FiPackage if icon is missing

  return (
    <CardContainer>
      <Box mb={2}>
        <IconComponent size={50} color={theme.palette.primary.main} />
      </Box>
      <CardContent>
        <Typography variant="h6" component="h3">
          {service.title}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {service.description}
        </Typography>
      </CardContent>
    </CardContainer>
  );
};

export default ServiceCard;
