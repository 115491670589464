import React, { useEffect, useState } from 'react';
import {
  Modal,
  Box,
  Tabs,
  Tab,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  MenuItem,
  Select,
} from '@mui/material';
import { getIntegrationById } from '../../../api';

const EditMarketIntegrationModal = ({ open, onClose, marketId, marketName }) => {
  const [integrationData, setIntegrationData] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchIntegration = async () => {
      try {
        setIsLoading(true);
        const data = await getIntegrationById(marketId, marketName);
        setIntegrationData(data);
      } catch (error) {
        console.error('Failed to fetch integration data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (open && marketId && marketName) {
      fetchIntegration();
    }
  }, [open, marketId, marketName]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setIntegrationData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleDropdownChange = (event) => {
    const { value } = event.target;
    console.log('Dropdown action selected:', value);
    // Handle delete or refresh token actions based on value
    if (value === 'delete') {
      console.log('Delete integration action initiated');
      // Add delete logic here
    } else if (value === 'refresh') {
      console.log('Refresh tokens action initiated');
      // Add refresh logic here
    }
  };

  if (isLoading) {
    return (
      <Modal open={open} onClose={onClose}>
        <Box sx={styles.modalBox}>
          <Typography>Loading...</Typography>
        </Box>
      </Modal>
    );
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={styles.modalBox}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="General" />
          <Tab label="Products" disabled />
          <Tab label="Orders" disabled />
        </Tabs>
        <div>
          {activeTab === 0 && integrationData && (
            <Box>
              <Typography variant="h6">General Information</Typography>
              <TextField
                label="Integration ID"
                value={integrationData.id || ''}
                InputProps={{ readOnly: true }}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Market Type"
                value={marketName || ''}
                InputProps={{ readOnly: true }}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Nickname"
                value={integrationData.nickname || ''}
                InputProps={{ readOnly: true }}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Created Date"
                value={new Date(integrationData.createdAt).toLocaleString() || ''}
                InputProps={{ readOnly: true }}
                fullWidth
                margin="normal"
              />

              <Typography variant="h6" sx={{ mt: 2 }}>
                Settings
              </Typography>
              {[
                { name: 'enabled', label: 'Market Enabled' },
                { name: 'inventoryEnabled', label: 'Inventory Upload' },
                { name: 'ordersEnabled', label: 'Order Download' },
                { name: 'autoCreateProducts', label: 'Auto-Create Products' },
                { name: 'autoFulfillOrders', label: 'Auto-Fulfill Orders' },
                { name: 'crossChannelEnabled', label: 'Cross Channel Fulfillment From FBA' },
              ].map((setting) => (
                <FormControlLabel
                  key={setting.name}
                  control={
                    <Checkbox
                      name={setting.name}
                      checked={integrationData[setting.name] || false}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label={setting.label}
                />
              ))}

              <Typography variant="h6" sx={{ mt: 2 }}>
                Tokens
              </Typography>
              <TextField
                label="Access Token"
                value={integrationData.accessToken || ''}
                InputProps={{ readOnly: true }}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Token Expiry"
                value={new Date(integrationData.tokenExpiration).toLocaleString() || ''}
                InputProps={{ readOnly: true }}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Refresh Token"
                value={integrationData.refreshToken || ''}
                InputProps={{ readOnly: true }}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Refresh Token Expiry"
                value={new Date(integrationData.refreshTokenExpiration).toLocaleString() || ''}
                InputProps={{ readOnly: true }}
                fullWidth
                margin="normal"
              />

              <Box sx={{ mt: 2 }}>
                <Select
                  displayEmpty
                  onChange={handleDropdownChange}
                  defaultValue=""
                  fullWidth
                >
                  <MenuItem value="" disabled>
                    Actions
                  </MenuItem>
                  <MenuItem value="delete">Delete Integration</MenuItem>
                  <MenuItem value="refresh">Refresh Tokens</MenuItem>
                </Select>
              </Box>
            </Box>
          )}

          {activeTab === 1 && (
            <Box>
              <Typography>Products tab content goes here.</Typography>
            </Box>
          )}

          {activeTab === 2 && (
            <Box>
              <Typography>Orders tab content goes here.</Typography>
            </Box>
          )}
        </div>
      </Box>
    </Modal>
  );
};

const styles = {
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '600px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
  },
};

export default EditMarketIntegrationModal;
