import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, Typography, DialogContent, DialogActions, Button, List, ListItem, ListItemText, ListItemAvatar, Avatar, CircularProgress } from '@mui/material';
import { getIntegrationsByCompanyId } from '../../../api'; //

const MarketIntegrationDialog = ({ open, onClose, onSave }) => {
  const [availableMarkets, setAvailableMarkets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMarket, setSelectedMarket] = useState(null);

  useEffect(() => {
    if (open) {
      setLoading(true);
      getIntegrationsByCompanyId()
        .then((markets) => {
          setAvailableMarkets(markets);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  }, [open]);

  const handleMarketSelect = (market) => {
    setSelectedMarket(market);
  };

  const handleSave = () => {
    if (selectedMarket) {
      onSave(selectedMarket);
      onClose();  // Close the modal after saving
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Select Market Integration</DialogTitle>
      <DialogContent dividers style={{ maxHeight: '300px', overflowY: 'auto' }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <List>
            {availableMarkets.length > 0 ? (
              availableMarkets.map((market) => (
                <ListItem
                  button
                  key={market.id}
                  selected={selectedMarket?.id === market.id}
                  onClick={() => handleMarketSelect(market)}
                >
                  <ListItemAvatar>
                    <Avatar src={market.icon} /> {/* Market icon */}
                  </ListItemAvatar>
                  <ListItemText primary={market.nickname} secondary={`ID: ${market.id}`} />
                </ListItem>
              ))
            ) : (
              <Typography>No available market integrations</Typography>
            )}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancel</Button>
        <Button onClick={handleSave} color="primary" disabled={!selectedMarket}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MarketIntegrationDialog;
