import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const PricingSection = () => {
    const navigate = useNavigate();

    const [users, setUsers] = useState(1);
    const [products, setProducts] = useState(0);
    const [orders, setOrders] = useState(0);
    const [integrations, setIntegrations] = useState(0);
    const [support, setSupport] = useState('help_center');

    const calculatePrice = () => {
        let basePrice = 99;
        if (users > 2) basePrice += (users <= 6 ? 100 : (users <= 10 ? 200 : 300));
        if (products > 500) basePrice += (products <= 1000 ? 100 : (products <= 2000 ? 200 : 300));
        if (orders > 500) basePrice += (orders <= 1000 ? 100 : (orders <= 2000 ? 200 : 300));
        if (integrations > 3) basePrice += (integrations <= 6 ? 100 : (integrations <= 10 ? 200 : 300));
        if (support === 'personal_manager') basePrice += 500;
        return Math.min(Math.max(basePrice, 99), 1000);
    };

    const handleBuyNow = () => {
        const subscriptionOptions = { users, products, orders, integrations, support, price: calculatePrice() };
        localStorage.setItem('subscriptionOptions', JSON.stringify(subscriptionOptions));
        navigate('/register');
    };

    return (
        <section style={{ backgroundColor: '#f7f7f7', padding: '125px 0 140px' }}>
            <div id="pricing" style={{ maxWidth: '1200px', margin: '0 auto' }}>
                <div style={{ marginBottom: '40px', textAlign: 'center' }}>
                    <h2 style={{ marginBottom: '25px', fontSize: '2rem', fontWeight: '700' }}>Customize Your Subscription</h2>
                    <div style={{ marginBottom: '20px', fontSize: '1rem', color: '#666' }}>
                        Adjust the options below to create a subscription plan that fits your needs.
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: '100%', maxWidth: '500px', backgroundColor: '#fff', padding: '40px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', textAlign: 'center' }}>
                        <div>
                            <h4 style={{ marginBottom: '15px', fontSize: '1.5rem', fontWeight: '600' }}>Custom Subscription</h4>
                            <div style={{ fontSize: '2rem', fontWeight: '700', color: '#702963' }}>
                                ${calculatePrice()}<span style={{ fontSize: '1rem', fontWeight: '400' }}> - Month</span>
                            </div>
                            <div style={{ fontStyle: 'italic', color: '#666', marginTop: '10px' }}>
                                Upgrade, downgrade, or cancel anytime with a 7-day free trial.
                            </div>
                        </div>

                        <div style={{ marginTop: '30px' }}>
                            {['Users', 'Product Count', 'Monthly Orders', 'Integrations', 'Support Level'].map((label, index) => (
                                <div key={index} style={{ marginBottom: '15px' }}>
                                    <label style={{ display: 'block', marginBottom: '5px', fontWeight: '500' }}>{label}</label>
                                    <select
                                        style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                                        value={(() => {
                                            switch (label) {
                                                case 'Users': return users;
                                                case 'Product Count': return products;
                                                case 'Monthly Orders': return orders;
                                                case 'Integrations': return integrations;
                                                case 'Support Level': return support;
                                                default: return '';
                                            }
                                        })()}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            switch (label) {
                                                case 'Users': setUsers(Number(value)); break;
                                                case 'Product Count': setProducts(Number(value)); break;
                                                case 'Monthly Orders': setOrders(Number(value)); break;
                                                case 'Integrations': setIntegrations(Number(value)); break;
                                                case 'Support Level': setSupport(value); break;
                                                default: break;
                                            }
                                        }}
                                    >
                                        {(() => {
                                            switch (label) {
                                                case 'Users': return [1, 3, 7, 11].map((val, i) => <option key={i} value={val}>{`${val === 1 ? '1-2' : val <= 6 ? '3-6' : val <= 10 ? '7-10' : '11+'}`}</option>);
                                                case 'Product Count': return [0, 501, 1001, 2001].map((val, i) => <option key={i} value={val}>{`${val === 0 ? '0-500' : val <= 1000 ? '501-1000' : val <= 2000 ? '1001-2000' : '2001+'}`}</option>);
                                                case 'Monthly Orders': return [0, 501, 1001, 2001].map((val, i) => <option key={i} value={val}>{`${val === 0 ? '0-500' : val <= 1000 ? '501-1000' : val <= 2000 ? '1001-2000' : '2001+'}`}</option>);
                                                case 'Integrations': return [0, 4, 7, 11].map((val, i) => <option key={i} value={val}>{`${val === 0 ? '0-3' : val <= 6 ? '4-6' : val <= 10 ? '7-10' : '11+'}`}</option>);
                                                case 'Support Level': return [
                                                    <option key="help" value="help_center">Help Center & Email Support</option>,
                                                    <option key="manager" value="personal_manager">Personal Account Manager</option>
                                                ];
                                                default: return null;
                                            }
                                        })()}
                                    </select>
                                </div>
                            ))}
                        </div>

                        <div style={{ marginTop: '20px' }}>
                            <button
                                style={{
                                    display: 'inline-block',
                                    padding: '10px 20px',
                                    backgroundColor: '#702963',
                                    color: '#fff',
                                    borderRadius: '5px',
                                    textDecoration: 'none',
                                    fontWeight: '600',
                                    border: 'none',
                                    cursor: 'pointer'
                                }}
                                onClick={handleBuyNow}
                            >
                                Buy Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PricingSection;
