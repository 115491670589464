import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getEbayTokens } from '../api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const EbayCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEbayToken = async () => {
      const params = new URLSearchParams(location.search);
      const code = params.get('code');
      const stateWithAuth = params.get('state');

      if (code && stateWithAuth) {
        try {
          await getEbayTokens({ code, stateWithAuth });

          toast.success('eBay account connected successfully');
          navigate('/dashboard');
        } catch (error) {
          toast.error('Failed to connect eBay account');
        }
      } else {
        toast.error('Invalid response from eBay');
      }
    };

    fetchEbayToken();
  }, [location.search, navigate]);

  return (
    <div>
      Connecting to eBay...
    </div>
  );
};

export default EbayCallback;
