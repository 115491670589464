import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import logo from '../assets/images/144x450_full_logo.png';
import { HashLink as Link } from 'react-router-hash-link';

const Header = () => (
  <div style={{ flexGrow: 1 }}>
    <AppBar position="static" color="primary" style={{ backgroundColor: '#1a1a1a' }}>
      <Toolbar style={{
        height: '80px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '0 20px',
      }}>
        {/* Logo aligned to the far left */}
        <Link to="/#" style={{ display: 'flex', alignItems: 'center', marginRight: 'auto' }}>
          <img src={logo} alt="StackrHub Logo" style={{ height: '50px' }} />
        </Link>

        {/* Centered Navigation Links */}
        <div style={{ display: 'flex', gap: '20px' }}>
          <Button color="inherit" component={RouterLink} to="/" style={{ color: '#ffffff', textTransform: 'none' }}>Home</Button>
          <ScrollLink to="features" smooth={true} duration={500} style={{ textDecoration: 'none' }}>
            <Button color="inherit" style={{ color: '#ffffff', textTransform: 'none' }}>Features</Button>
          </ScrollLink>
          <ScrollLink to="pricing" smooth={true} duration={500} style={{ textDecoration: 'none' }}>
            <Button color="inherit" style={{ color: '#ffffff', textTransform: 'none' }}>Pricing</Button>
          </ScrollLink>
          <ScrollLink to="contact" smooth={true} duration={500} style={{ textDecoration: 'none' }}>
            <Button color="inherit" style={{ color: '#ffffff', textTransform: 'none' }}>Contact</Button>
          </ScrollLink>
          <Button color="inherit" component={RouterLink} to="/login" style={{ color: '#ffffff', textTransform: 'none' }}>Login</Button>
          <Button color="inherit" component={RouterLink} to="/register" style={{ color: '#ffffff', textTransform: 'none' }}>Register</Button>
        </div>
      </Toolbar>
    </AppBar>
  </div>
);

export default Header;
