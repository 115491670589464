import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, Box, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SubscriptionInfoCard = () => {
  const navigate = useNavigate();

  const [subscriptionInfo, setSubscriptionInfo] = useState({
    level: 'Basic',
    nextBillingDate: '2023-12-31',
    previousBilling: '2023-11-30',
    billingStatus: 'Paid',
    users: 1,
    products: 0,
    orders: 0,
    integrations: 0,
    support: 'help_center',
  });

  const [openModal, setOpenModal] = useState(false);

  const calculatePrice = () => {
    let basePrice = 99;
    if (subscriptionInfo.users > 2) basePrice += (subscriptionInfo.users <= 6 ? 100 : (subscriptionInfo.users <= 10 ? 200 : 300));
    if (subscriptionInfo.products > 500) basePrice += (subscriptionInfo.products <= 1000 ? 100 : (subscriptionInfo.products <= 2000 ? 200 : 300));
    if (subscriptionInfo.orders > 500) basePrice += (subscriptionInfo.orders <= 1000 ? 100 : (subscriptionInfo.orders <= 2000 ? 200 : 300));
    if (subscriptionInfo.integrations > 3) basePrice += (subscriptionInfo.integrations <= 6 ? 100 : (subscriptionInfo.integrations <= 10 ? 200 : 300));
    if (subscriptionInfo.support === 'personal_manager') basePrice += 500;
    return Math.min(Math.max(basePrice, 99), 1000);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSubscriptionChange = (e, field) => {
    const value = e.target.value;
    setSubscriptionInfo(prevInfo => ({
      ...prevInfo,
      [field]: field === 'support' ? value : parseInt(value, 10),
    }));
  };

  const handleSaveSubscription = () => {
    // Logic to save/update subscription info
    const updatedInfo = { ...subscriptionInfo, price: calculatePrice() };
    setSubscriptionInfo(updatedInfo);
    localStorage.setItem('subscriptionOptions', JSON.stringify(updatedInfo));
    setOpenModal(false);
  };

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Subscription Information</Typography>
          <Button variant="outlined" onClick={handleOpenModal}>
            Change Subscription
          </Button>
        </Box>
        <Typography variant="body1">Subscription Level: {subscriptionInfo.level}</Typography>
        <Typography variant="body1">Next Billing Date: {subscriptionInfo.nextBillingDate}</Typography>
        <Typography variant="body1">Previous Billing: {subscriptionInfo.previousBilling}</Typography>
        <Typography variant="body1">Billing Status: {subscriptionInfo.billingStatus}</Typography>
        <Typography variant="body1">Monthly Price: ${calculatePrice()}</Typography>
      </CardContent>

      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogTitle>Customize Your Subscription</DialogTitle>
        <DialogContent>
          {['Users', 'Product Count', 'Monthly Orders', 'Integrations', 'Support Level'].map((label, index) => (
            <Box key={index} mb={2}>
              <Typography variant="body2" gutterBottom>{label}</Typography>
              <select
                style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                value={(() => {
                  switch (label) {
                    case 'Users': return subscriptionInfo.users;
                    case 'Product Count': return subscriptionInfo.products;
                    case 'Monthly Orders': return subscriptionInfo.orders;
                    case 'Integrations': return subscriptionInfo.integrations;
                    case 'Support Level': return subscriptionInfo.support;
                    default: return '';
                  }
                })()}
                onChange={(e) => handleSubscriptionChange(e, (() => {
                  switch (label) {
                    case 'Users': return 'users';
                    case 'Product Count': return 'products';
                    case 'Monthly Orders': return 'orders';
                    case 'Integrations': return 'integrations';
                    case 'Support Level': return 'support';
                    default: return '';
                  }
                })())}
              >
                {(() => {
                  switch (label) {
                    case 'Users': return [1, 3, 7, 11].map((val, i) => <option key={i} value={val}>{`${val === 1 ? '1-2' : val <= 6 ? '3-6' : val <= 10 ? '7-10' : '11+'}`}</option>);
                    case 'Product Count': return [0, 501, 1001, 2001].map((val, i) => <option key={i} value={val}>{`${val === 0 ? '0-500' : val <= 1000 ? '501-1000' : val <= 2000 ? '1001-2000' : '2001+'}`}</option>);
                    case 'Monthly Orders': return [0, 501, 1001, 2001].map((val, i) => <option key={i} value={val}>{`${val === 0 ? '0-500' : val <= 1000 ? '501-1000' : val <= 2000 ? '1001-2000' : '2001+'}`}</option>);
                    case 'Integrations': return [0, 4, 7, 11].map((val, i) => <option key={i} value={val}>{`${val === 0 ? '0-3' : val <= 6 ? '4-6' : val <= 10 ? '7-10' : '11+'}`}</option>);
                    case 'Support Level': return [
                      <option key="help" value="help_center">Help Center & Email Support</option>,
                      <option key="manager" value="personal_manager">Personal Account Manager</option>
                    ];
                    default: return null;
                  }
                })()}
              </select>
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSaveSubscription} color="primary" variant="contained">
            Save Subscription
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default SubscriptionInfoCard;
