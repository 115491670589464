import React from 'react';
import { Card, CardContent, Typography, Box, Button } from '@mui/material';
import { resetEmailPassword } from '../../../api';

const UserInfoCard = ({ userInfo }) => {
  const handleResetPassword = async () => {
    try {
      await resetEmailPassword(userInfo.id);  // Trigger password reset email
      alert('Password reset email sent successfully');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      alert('Failed to send password reset email');
    }
  };

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">User Information</Typography>
        </Box>
        <Typography variant="body1">Name: {userInfo.name}</Typography>
        <Typography variant="body1">Email: {userInfo.email}</Typography>
        <Button variant="contained" color="primary" onClick={handleResetPassword}>
          Reset Password
        </Button>
      </CardContent>
    </Card>
  );
};

export default UserInfoCard;
