import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Tabs, Tab,
  Table, TableBody, TableCell, TableHead, TableRow, IconButton, Menu, MenuItem, Autocomplete, Select, FormControl, InputLabel
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { toast } from 'react-toastify';
import { getPurchaseOrderById, updatePurchaseOrder, searchProductsTop3, getVendorById } from '../../../api';
import { useLoading } from '../../../utils/LoadingSpinner';

const EditPurchaseOrderModal = ({ open, onClose, purchaseOrderId, onSave }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [purchaseOrder, setPurchaseOrder] = useState(null);
  const [productList, setProductList] = useState([]);
  const [vendor, setVendor] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const { setLoading } = useLoading();

  useEffect(() => {
    if (open && purchaseOrderId) {
      getPurchaseOrderById(purchaseOrderId).then((data) => {
        setPurchaseOrder(data);
        setProductList(data.productDetails || []);
      });
    }
  }, [open, purchaseOrderId]);

  useEffect(() => {
    if (purchaseOrder && purchaseOrder.vendorId) {
      getVendorById(purchaseOrder.vendorId).then(setVendor);
    }
  }, [purchaseOrder]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleProductSearch = async (event) => {
    const query = event.target.value;
    if (query.length >= 3) {
      setLoading(true)
      try {
        const results = await searchProductsTop3(query);
        setSearchResults(results);
      } catch (error) {
        console.error('Error searching products:', error);
      } finally {
        setLoading(false)
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleAddProduct = () => {
    if (selectedProduct) {
      setProductList([...productList, {
        ...selectedProduct,
        quantityOrdered: 0,
        quantityShipped: 0,
        quantityReceived: 0,
        price: 0.0
      }]);
      setSelectedProduct(null);
    } else {
      toast.error('Please select a product.');
    }
  };

  const handleMenuClick = (event, productId) => {
    setAnchorEl(event.currentTarget);
    setSelectedProductId(productId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditProduct = (index, key, value) => {
    const updatedProducts = productList.map((product, i) => {
      if (i === index) {
        return {
          ...product,
          [key]: value,
        };
      }
      return product;
    });
    setProductList(updatedProducts);
  };

  const handleDeleteProduct = () => {
    setProductList(productList.filter((product) => product.id !== selectedProductId));
    handleMenuClose();
  };

  const handleSave = async () => {
    try {
      await updatePurchaseOrder(purchaseOrderId, {
        ...purchaseOrder,
        productDetails: productList, // Ensure product details are updated
      });
      onSave();
      toast.success('Purchase Order updated successfully');
      onClose();
    } catch (error) {
      console.error('Error updating purchase order:', error);
      toast.error('Failed to update Purchase Order');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>Edit Purchase Order</DialogTitle>
      <DialogContent>
        <Tabs value={activeTab} onChange={handleTabChange} aria-label="purchase order tabs">
          <Tab label="Products" />
          <Tab label="Info" />
          <Tab label="Vendor" />
        </Tabs>
        {activeTab === 0 && (
          <Box>
            <Autocomplete
              options={searchResults}
              getOptionLabel={(option) => `${option.id} - ${option.sku} - ${option.name}`}
              value={selectedProduct}
              onInputChange={handleProductSearch}
              onChange={(event, newValue) => setSelectedProduct(newValue)}
              renderInput={(params) => <TextField {...params} label="Search Product" margin="normal" />}
            />
            <Button onClick={handleAddProduct} variant="contained" color="primary" fullWidth style={{ marginTop: '16px' }}>
              Add Product
            </Button>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell>Qty Ordered</TableCell>
                  <TableCell>Qty Shipped</TableCell>
                  <TableCell>Qty Received</TableCell>
                  <TableCell>Cost</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productList.map((product, index) => (
                  <TableRow key={index}>
                    <TableCell>{product.id}</TableCell>
                    <TableCell>{product.sku}</TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={product.quantityOrdered}
                        onChange={(e) => handleEditProduct(index, 'quantityOrdered', Number(e.target.value))}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={product.quantityShipped}
                        onChange={(e) => handleEditProduct(index, 'quantityShipped', Number(e.target.value))}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={product.quantityReceived}
                        onChange={(e) => handleEditProduct(index, 'quantityReceived', Number(e.target.value))}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={product.price}
                        onChange={(e) => handleEditProduct(index, 'price', Number(e.target.value))}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={(event) => handleMenuClick(event, product.id)}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && selectedProductId === product.id}
                        onClose={handleMenuClose}
                      >
                        <MenuItem onClick={() => handleEditProduct(index, 'quantityReceived', product.quantityOrdered)}>
                          Fully Receive
                        </MenuItem>
                        <MenuItem onClick={handleDeleteProduct}>Delete</MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        )}
        {activeTab === 1 && purchaseOrder && (
          <Box>
            <TextField
              label="PO ID"
              fullWidth
              margin="normal"
              value={purchaseOrder.id}
              InputProps={{ readOnly: true }}
            />
            <TextField
              label="PO Name"
              fullWidth
              margin="normal"
              value={purchaseOrder.name}
              onChange={(e) => setPurchaseOrder({ ...purchaseOrder, name: e.target.value })}
            />
            <TextField
              label="External Order ID"
              fullWidth
              margin="normal"
              value={purchaseOrder.externalOrderId}
              onChange={(e) => setPurchaseOrder({ ...purchaseOrder, externalOrderId: e.target.value })}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Payment Status</InputLabel>
              <Select
                native
                value={purchaseOrder.paymentStatus}
                onChange={(e) => setPurchaseOrder({ ...purchaseOrder, paymentStatus: e.target.value })}
              >
                <option value="UNPAID">UNPAID</option>
                <option value="PAID">PAID</option>
                <option value="PARTIAL_PAYMENT">PARTIAL PAYMENT</option>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Shipping Status</InputLabel>
              <Select
                native
                value={purchaseOrder.shippingStatus}
                onChange={(e) => setPurchaseOrder({ ...purchaseOrder, shippingStatus: e.target.value })}
              >
                <option value="UNSHIPPED">UNSHIPPED</option>
                <option value="SHIPPED">SHIPPED</option>
                <option value="PARTIAL_SHIP">PARTIAL SHIP</option>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Receiving Status</InputLabel>
              <Select
                native
                value={purchaseOrder.receivingStatus}
                onChange={(e) => setPurchaseOrder({ ...purchaseOrder, receivingStatus: e.target.value })}
              >
                <option value="UNRECEIVED">UNRECEIVED</option>
                <option value="RECEIVED">RECEIVED</option>
                <option value="PARTIAL_RECEIVE">PARTIAL RECEIVE</option>
              </Select>
            </FormControl>
          </Box>
        )}
        {activeTab === 2 && vendor && (
          <Box>
            <TextField
              label="Vendor ID"
              fullWidth
              margin="normal"
              value={vendor.id}
              InputProps={{ readOnly: true }}
            />
            <TextField
              label="Vendor Name"
              fullWidth
              margin="normal"
              value={vendor.name}
              InputProps={{ readOnly: true }}
            />
            <TextField
              label="Vendor Contact"
              fullWidth
              margin="normal"
              value={vendor.contact}
              InputProps={{ readOnly: true }}
            />
            <Box marginTop={2}>
              <h3>Recent Purchase Orders</h3>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>PO ID</TableCell>
                    <TableCell>PO Name</TableCell>
                    <TableCell>Order Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vendor.recentPOs.map((po) => (
                    <TableRow key={po.id}>
                      <TableCell>{po.id}</TableCell>
                      <TableCell>{po.name}</TableCell>
                      <TableCell>{po.orderDate}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPurchaseOrderModal;
