import React, { useState, useEffect } from 'react';
import {
  Modal, Box, Typography, List, ListItem, ListItemText, ListItemIcon, IconButton, TextField, Checkbox, Button, Divider
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { getCompanyWarehouses, updateWarehouseInfo, createWarehouse } from '../../../api';
import { useLoading } from '../../../utils/LoadingSpinner';

const WarehousesModal = ({ open, handleClose }) => {
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const { setLoading } = useLoading();

  useEffect(() => {
    if (open) {
      fetchWarehouses();
    }
  }, [open]);

  const fetchWarehouses = async () => {
    setLoading(true)
    try {
      const response = await getCompanyWarehouses();
      setWarehouses(response);
      if (response.length > 0) {
        const defaultWarehouse = response.find(wh => wh.isDefault) || response[0];
        setSelectedWarehouseId(defaultWarehouse.id);
        setSelectedWarehouse(defaultWarehouse);
      }
    } catch (error) {
      console.error('Error fetching warehouses:', error);
    }finally{
      setLoading(false)
    }
  };

  const handleWarehouseClick = (warehouse) => {
    setSelectedWarehouseId(warehouse.id);
    setSelectedWarehouse(warehouse);
  };

  const handleAddWarehouse = () => {
    const newWarehouse = {
      name: 'New Warehouse',
      address: '',
      isDefault: false
    };
    setWarehouses([...warehouses, newWarehouse]);
    setSelectedWarehouseId(newWarehouse.id);
    setSelectedWarehouse(newWarehouse);
  };

  const handleSaveWarehouse = async () => {
    setLoading(true)
    try {
      if (selectedWarehouse.id) {
        await updateWarehouseInfo(selectedWarehouse.id, selectedWarehouse);
      } else {
        await createWarehouse(selectedWarehouse);
        fetchWarehouses(); // Refresh the list after adding
      }
      handleClose();
    } catch (error) {
      console.error('Error saving warehouse:', error);
    }finally{
      setLoading(false)
    }
  };

  const handleChange = (event) => {
    setSelectedWarehouse({
      ...selectedWarehouse,
      [event.target.name]: event.target.value,
    });
  };

  const handleToggleDefault = (event) => {
    const isDefault = event.target.checked;
    const updatedWarehouses = warehouses.map(wh =>
      wh.id === selectedWarehouse.id
        ? { ...wh, isDefault: isDefault }
        : { ...wh, isDefault: false }
    );

    setWarehouses(updatedWarehouses);
    setSelectedWarehouse({
      ...selectedWarehouse,
      isDefault: isDefault,
    });
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box display="flex" width="80%" height="80%" bgcolor="white" margin="auto" marginTop="10%" padding="20px" boxShadow={24}>
        <Box flex="1" marginRight="20px">
          <Typography variant="h6">Warehouses</Typography>
          <List>
            {warehouses.map((warehouse) => (
              <ListItem key={warehouse.id} button onClick={() => handleWarehouseClick(warehouse)}>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <ListItemText primary={warehouse.name} />
                  {warehouse.isDefault && (
                    <Typography component="span" variant="body2" color="primary">
                      DEFAULT
                    </Typography>
                  )}
                </Box>
              </ListItem>
            ))}
            <ListItem button onClick={handleAddWarehouse}>
              <ListItemIcon>
                <IconButton>
                  <AddIcon />
                </IconButton>
              </ListItemIcon>
              <ListItemText primary="Add New Warehouse" />
            </ListItem>
          </List>
        </Box>

        <Divider orientation="vertical" flexItem />

        {selectedWarehouse && (
          <Box flex="2" marginLeft="20px">
            <Typography variant="h6">Warehouse Details</Typography>
            <TextField
              fullWidth
              margin="normal"
              label="Warehouse Name"
              name="name"
              value={selectedWarehouse.name}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Warehouse Address"
              name="address"
              value={selectedWarehouse.address}
              onChange={handleChange}
            />
            <Box display="flex" alignItems="center" marginTop="10px">
              <Checkbox
                checked={selectedWarehouse.isDefault}
                onChange={handleToggleDefault}
              />
              <Typography>Set as Default</Typography>
            </Box>
            <Box marginTop="20px">
              <Button variant="contained" color="primary" onClick={handleSaveWarehouse}>
                Save Warehouse
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default WarehousesModal;
