import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import FeatureCard from './FeatureCard'; // Create this component to display individual features
import introMobileFeatures from '../assets/images/intro-mobile-features.png';

const features = [
  {
    title: 'Multi-Marketplace Integrations',
    description: 'Effortlessly connect with popular marketplaces like eBay, Etsy, Shopify, and more, all from a unified platform.',
    icon: 'FiPackage'
  },
  {
    title: 'Flexible Bundle and Variation Management',
    description: 'Easily manage product bundles and variations to maintain accurate inventory and simplify multi-channel sales.',
    icon: 'FiShoppingCart'
  },
  {
    title: 'Customizable Automation Rules',
    description: 'Define and implement custom rules for pricing, inventory, and order processing to streamline operations.',
    icon: 'FiRefreshCw'
  },
  {
    title: 'Professional Listing Templates',
    description: 'Create professional, high-quality product listings tailored to boost visibility and sales across all platforms.',
    icon: 'FiBarChart2'
  },
  {
    title: 'Detailed Sales and Inventory Insights',
    description: 'Get comprehensive insights into sales trends, customer behavior, and stock levels to make informed decisions.',
    icon: 'FiLayers'
  },
  {
    title: 'Integrated Order Fulfillment',
    description: 'Seamlessly connect with major shipping and fulfillment providers to simplify and automate the order process.',
    icon: 'FiUser'
  },
];

const FeaturesSection = () => (
  <Container maxWidth="lg" id="features" component="section" style={{ padding: '80px 0' }}>
    <Typography component="h2" variant="h4" align="center" color="textPrimary" gutterBottom>
      Features
    </Typography>
    <Grid container spacing={4} alignItems="center">
      <Grid item xs={12} md={4}>
        <Grid container spacing={4}>
          {features.slice(0, 3).map((feature) => (
            <Grid item key={feature.title} xs={12}>
              <FeatureCard feature={feature} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box display="flex" justifyContent="center">
          <img src={introMobileFeatures} alt="Features" style={{ width: '100%', maxWidth: '300px' }} />
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid container spacing={4}>
          {features.slice(3).map((feature) => (
            <Grid item key={feature.title} xs={12}>
              <FeatureCard feature={feature} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  </Container>
);

export default FeaturesSection;
