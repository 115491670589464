import React, { useState } from 'react';
import { Container, Grid, TextField, Typography, Button, Box } from '@mui/material';
import { styled } from '@mui/system';
import { sendContactRequest } from './../api';

const FormField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      await sendContactRequest(formData);
      alert('Message sent successfully');
      setFormData({ name: '', email: '', message: '' });
    } catch (err) {
      setError('Failed to send message. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
      <Container maxWidth="md" id="contact" component="section" style={{ padding: '80px 0' }}>
      <Typography component="h2" variant="h4" align="center" color="textPrimary" gutterBottom>
        Contact Us
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <FormField
              fullWidth
              label="Name"
              variant="outlined"
              name="name"
              required
              value={formData.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              fullWidth
              label="Email"
              variant="outlined"
              name="email"
              type="email"
              required
              value={formData.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              fullWidth
              label="Message"
              variant="outlined"
              name="message"
              required
              multiline
              rows={4}
              value={formData.message}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              {loading ? 'Sending...' : 'Send Message'}
            </Button>
          </Grid>
        </Grid>
      </form>
      {error && (
        <Typography variant="body2" color="error" align="center">
          {error}
        </Typography>
      )}
    </Container>
  );
};

export default ContactSection;
