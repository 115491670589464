import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TableContainer, List, ListItem, ListItemText, Button, TextField, Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material';
import { toast } from 'react-toastify';
import { fetchProductGroups, searchProductsTop3, addProductToGroup } from '../../../api';
import { useLoading } from '../../../utils/LoadingSpinner';

const ProductGroupsModal = ({ open, handleClose }) => {
  const [productGroups, setProductGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const { setLoading } = useLoading();

  useEffect(() => {
    if (open) {
      fetchProductGroups()
        .then((response) => {
          setProductGroups(response);
        })
        .catch(() => {
          toast.error('Error fetching product groups.');
        });
    }
  }, [open]);

  // Handle group selection
  const handleGroupSelect = (group) => {
    setSelectedGroup(group);
  };

  // Search for products
  const handleSearch = async (event) => {
    
    const query = event.target.value;
    if (query.length >= 3) {
      setLoading(true)
      try {
        const results = await searchProductsTop3(query);
        setSearchResults(results);
      } catch (error) {
        console.error('Error searching products:', error);
      }finally{
        setLoading(false)
      }
    } else {
      setSearchResults([]);
    }
  };

  // Add product to the selected group
  const handleAddProduct = (productId) => {
    if (selectedGroup) {
      addProductToGroup(selectedGroup.id, productId)
        .then(() => {
          toast.success('Product added to the group.');
        })
        .catch(() => {
          toast.error('Error adding product to the group.');
        });
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxHeight: '90vh',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          overflowY: 'auto',
        }}
      >
        <Typography variant="h5" gutterBottom>
          Product Groups
        </Typography>

        <Box display="flex" gap={4}>
          {/* Left side: Product Groups List */}
          <Box width="30%">
            <Typography variant="h6">Groups</Typography>
            <List component="nav">
              {productGroups.map((group) => (
                <ListItem
                  key={group.id}
                  button
                  selected={selectedGroup && selectedGroup.id === group.id}
                  onClick={() => handleGroupSelect(group)}
                >
                  <ListItemText
                    primary={group.name}
                    secondary={`Products: ${group.productCount} | Created: ${new Date(group.createdDate).toLocaleDateString()}`}
                  />
                </ListItem>
              ))}
            </List>
          </Box>

          {/* Right side: Selected Group Products */}
          <Box width="70%">
            {selectedGroup ? (
              <>
                <Typography variant="h6">Products in {selectedGroup.name}</Typography>

                {/* Products Table */}
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>SKU</TableCell>
                        <TableCell>Name</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedGroup.products.map((product) => (
                        <TableRow key={product.id}>
                          <TableCell>{product.id}</TableCell>
                          <TableCell>{product.sku}</TableCell>
                          <TableCell>{product.name}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* Search and Add Product to Group */}
                <Box mt={4}>
                  <Typography variant="h6">Add Product to Group</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Search Products"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <Button variant="contained" onClick={handleSearch} sx={{ mt: 2 }}>
                    Search
                  </Button>

                  {/* Search Results */}
                  <List>
                    {searchResults.map((product) => (
                      <ListItem key={product.id}>
                        <ListItemText primary={`${product.sku} - ${product.name}`} />
                        <Button variant="contained" onClick={() => handleAddProduct(product.id)}>
                          Add
                        </Button>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </>
            ) : (
              <Typography variant="h6">Select a group to see the products.</Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProductGroupsModal;
