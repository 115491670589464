import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemText, Typography, Box, Tabs, Tab, TextField, IconButton, Grid, Table, TableHead, TableRow, TableCell, TableBody
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { getVendorsByCompanyId, saveVendor, getPurchaseOrdersByVendorId } from '../../../api';
import { toast } from 'react-toastify';
import { useLoading } from '../../../utils/LoadingSpinner';

const VendorsModal = ({ open, onClose, companyId }) => {
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [vendorDetails, setVendorDetails] = useState({
    name: '',
    addressStreet1: '',
    addressStreet2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    contacts: [{ name: '', email: '', phone: '' }],
  });
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const { setLoading } = useLoading();

  useEffect(() => {
    if (open) {
      fetchVendors();
    }
  }, [open]);

const fetchVendors = async () => {
  setLoading(true)
  try {
    const response = await getVendorsByCompanyId();
    setVendors(response);
    if (response.length > 0) {
      selectVendor(response[0]);
    }
  } catch (error) {
    console.error('Failed to fetch vendors:', error);
  } finally {
    setLoading(false)
  }
};


  const fetchPurchaseOrders = async (vendorId) => {
    setLoading(true)
    try {
      const response = await getPurchaseOrdersByVendorId(vendorId);
      setPurchaseOrders(response);
    } catch (error) {
      console.error('Failed to fetch purchase orders:', error);
      toast.error('Failed to fetch purchase orders');
    } finally {
      setLoading(false)
    }
  };

  const selectVendor = (vendor) => {
    setSelectedVendor(vendor);
    setVendorDetails({
      name: vendor.name || '',
      addressStreet1: vendor.addressStreet1 || '',
      addressStreet2: vendor.addressStreet2 || '',
      city: vendor.city || '',
      state: vendor.state || '',
      zip: vendor.zip || '',
      phone: vendor.phone || '',
      contacts: vendor.contacts || [{ name: '', email: '', phone: '' }],
    });
    setSelectedTab(0);
    setPurchaseOrders([]);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    if (newValue === 2 && selectedVendor) {
      fetchPurchaseOrders(selectedVendor.id);
    }
  };

  const handleAddVendor = () => {
    const newVendor = { id: null, name: '', companyId };
    setVendors([...vendors, newVendor]);
    selectVendor(newVendor);
  };

  const handleVendorDetailsChange = (field) => (event) => {
    setVendorDetails({ ...vendorDetails, [field]: event.target.value });
  };

  const handleContactChange = (index, field) => (event) => {
    const newContacts = [...vendorDetails.contacts];
    newContacts[index][field] = event.target.value;
    setVendorDetails({ ...vendorDetails, contacts: newContacts });
  };

  const addNewContact = () => {
    setVendorDetails({ ...vendorDetails, contacts: [...vendorDetails.contacts, { name: '', email: '', phone: '' }] });
  };

  const removeContact = (index) => {
    setVendorDetails({ ...vendorDetails, contacts: vendorDetails.contacts.filter((_, i) => i !== index) });
  };

const handleSaveVendor = async () => {
  setLoading(true)
  try {
    const updatedVendor = {
      ...selectedVendor,
      ...vendorDetails,
      company: companyId,
    };
    const response = await saveVendor(updatedVendor);
    if (updatedVendor.id) {
      setVendors(vendors.map(v => (v.id === updatedVendor.id ? response : v)));
    } else {
      setVendors(vendors.map(v => (v === selectedVendor ? response : v)));
    }
    setSelectedVendor(response);
    toast.success('Vendor saved successfully');
  } catch (error) {
    console.error('Failed to save vendor:', error);
    toast.error('Failed to save vendor');
  } finally {
    setLoading(false)
  }
};

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        Manage Vendors
        <IconButton onClick={onClose} style={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ display: 'flex' }}>
        <Box style={{ width: '30%', borderRight: '1px solid #ccc', overflowY: 'auto' }}>
          <Button variant="contained" color="primary" onClick={handleAddVendor} fullWidth style={{ marginBottom: '16px' }}>
            Add Vendor
          </Button>
          <List>
            {vendors.map((vendor) => (
              <ListItem
                button
                key={vendor.id || Math.random()}
                selected={selectedVendor?.id === vendor.id}
                onClick={() => selectVendor(vendor)}
              >
                <ListItemText primary={vendor.name || 'New Vendor'} />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box style={{ width: '70%', padding: '16px' }}>
          {selectedVendor ? (
            <Box>
              <Tabs value={selectedTab} onChange={handleTabChange}>
                <Tab label="Details" />
                <Tab label="Contacts" />
                <Tab label="History" />
              </Tabs>
              <Box style={{ padding: '16px' }}>
                {selectedTab === 0 && (
                  <Box>
                    <Typography variant="h6">Vendor Details</Typography>
                    <TextField
                      fullWidth
                      label="Name"
                      margin="normal"
                      value={vendorDetails.name}
                      onChange={handleVendorDetailsChange('name')}
                    />
                    <TextField
                      fullWidth
                      label="Address Line 1"
                      margin="normal"
                      value={vendorDetails.addressStreet1}
                      onChange={handleVendorDetailsChange('addressStreet1')}
                    />
                    <TextField
                      fullWidth
                      label="Address Line 2"
                      margin="normal"
                      value={vendorDetails.addressStreet2}
                      onChange={handleVendorDetailsChange('addressStreet2')}
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          label="City"
                          margin="normal"
                          value={vendorDetails.city}
                          onChange={handleVendorDetailsChange('city')}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          label="State"
                          margin="normal"
                          value={vendorDetails.state}
                          onChange={handleVendorDetailsChange('state')}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          label="Zip Code"
                          margin="normal"
                          value={vendorDetails.zip}
                          onChange={handleVendorDetailsChange('zip')}
                        />
                      </Grid>
                    </Grid>
                    <TextField
                      fullWidth
                      label="Phone"
                      margin="normal"
                      value={vendorDetails.phone}
                      onChange={handleVendorDetailsChange('phone')}
                    />
                  </Box>
                )}
                {selectedTab === 1 && (
                  <Box>
                    <Typography variant="h6">Contacts</Typography>
                    {vendorDetails.contacts.map((contact, index) => (
                      <Box key={index} display="flex" alignItems="center" mb={2}>
                        <TextField
                          fullWidth
                          label="Contact Name"
                          margin="normal"
                          value={contact.name}
                          onChange={handleContactChange(index, 'name')}
                          style={{ marginRight: '8px' }}
                        />
                        <TextField
                          fullWidth
                          label="Email"
                          margin="normal"
                          value={contact.email}
                          onChange={handleContactChange(index, 'email')}
                          style={{ marginRight: '8px' }}
                        />
                        <TextField
                          fullWidth
                          label="Phone"
                          margin="normal"
                          value={contact.phone}
                          onChange={handleContactChange(index, 'phone')}
                          style={{ marginRight: '8px' }}
                        />
                        <IconButton onClick={() => removeContact(index)} aria-label="remove" style={{ marginLeft: '8px' }}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    ))}
                    <Button
                      startIcon={<AddIcon />}
                      onClick={addNewContact}
                      variant="contained"
                      color="primary"
                      style={{ marginTop: '16px' }}
                    >
                      Add Contact
                    </Button>
                  </Box>
                )}
                {selectedTab === 2 && (
                  <Box>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="h6">Purchase History</Typography>
                      <IconButton onClick={() => fetchPurchaseOrders(selectedVendor.id)} aria-label="refresh">
                        <RefreshIcon />
                      </IconButton>
                    </Box>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>PO #</TableCell>
                          <TableCell>Order #</TableCell>
                          <TableCell>Products</TableCell>
                          <TableCell>Total Owed</TableCell>
                          <TableCell>Total Paid</TableCell>
                          <TableCell>Fulfilled</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {purchaseOrders.map((po, index) => (
                          <TableRow key={index}>
                            <TableCell>{po.poId}</TableCell>
                            <TableCell>{po.orderId}</TableCell>
                            <TableCell>{po.products}</TableCell>
                            <TableCell>{po.totalOwed}</TableCell>
                            <TableCell>{po.totalPaid}</TableCell>
                            <TableCell>
                              {po.fulfilled ? (
                                <CheckCircleIcon color="success" />
                              ) : (
                                <CancelIcon color="error" />
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            <Typography variant="h6" align="center" style={{ marginTop: '20px' }}>
              Select a vendor to view details
            </Typography>
          )}
        </Box>
      </DialogContent>
      {selectedVendor && (
        <DialogActions>
          <Button onClick={handleSaveVendor} color="primary">
            Save Vendor
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default VendorsModal;
