import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { HashLink as Link } from 'react-router-hash-link';
import whiteLogo from '../assets/images/144x450_full_logo.png';

const Footer = () => {
    return (
        <footer style={{
            backgroundColor: '#1a1a1a',
            color: '#ffffff',
            padding: '40px 0',
            fontSize: '14px'
        }}>
            <div style={{ maxWidth: '1200px', margin: '0 auto', paddingBottom: '30px' }}>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', paddingBottom: '30px' }}>
                    <Fade fade delay={100}>
                        <div style={{ flex: '1 1 200px', marginBottom: '20px' }}>
                            <div>
                                <Link to="#"><img src={whiteLogo} alt="StackrHub Logo" style={{ maxWidth: '150px', marginBottom: '15px' }} /></Link>
                                <p style={{ fontSize: '14px', color: '#b5b5b5', marginBottom: '20px' }}>Full e-commerce marketplace management for online sellers.</p>
                                <ul style={{ display: 'flex', gap: '10px', listStyle: 'none', padding: 0 }}>
                                    <li><a href="https://www.facebook.com/profile.php?id=61567753444451" target="_blank" rel="noreferrer" style={{ color: '#b5b5b5', fontSize: '18px' }}><i className="ri-facebook-fill"></i></a></li>
                                    <li><a href="https://www.twitter.com/" target="_blank" rel="noreferrer" style={{ color: '#b5b5b5', fontSize: '18px' }}><i className="ri-twitter-fill"></i></a></li>
                                    <li><a href="https://www.linkedin.com/" target="_blank" rel="noreferrer" style={{ color: '#b5b5b5', fontSize: '18px' }}><i className="ri-linkedin-fill"></i></a></li>
                                    <li><a href="https://www.instagram.com/" target="_blank" rel="noreferrer" style={{ color: '#b5b5b5', fontSize: '18px' }}><i className="ri-instagram-fill"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </Fade>
                    <Fade fade delay={200}>
                        <div style={{ flex: '1 1 200px', marginBottom: '20px' }}>
                            <h4 style={{ fontSize: '16px', fontWeight: '600', color: '#ffffff', marginBottom: '15px' }}>Company</h4>
                            <ul style={{ listStyle: 'none', padding: 0 }}>
                                <li><Link to="/about#" style={{ color: '#b5b5b5', textDecoration: 'none' }}>About Us</Link></li>
                                <li><Link to="/privacy-policy" style={{ color: '#b5b5b5', textDecoration: 'none' }}>Privacy Policy</Link></li>
                                <li><Link to="/terms-of-service" style={{ color: '#b5b5b5', textDecoration: 'none' }}>Terms & Conditions</Link></li>
                                <li><Link to="/contact#" style={{ color: '#b5b5b5', textDecoration: 'none' }}>Contact</Link></li>
                            </ul>
                        </div>
                    </Fade>
                    <Fade fade delay={300}>
                        <div style={{ flex: '1 1 200px', marginBottom: '20px' }}>
                            <h4 style={{ fontSize: '16px', fontWeight: '600', color: '#ffffff', marginBottom: '15px' }}>Resources</h4>
                            <ul style={{ listStyle: 'none', padding: 0 }}>
                                <li><Link to="/products#" style={{ color: '#b5b5b5', textDecoration: 'none' }}>Products</Link></li>
                                <li><Link to="/pricing#" style={{ color: '#b5b5b5', textDecoration: 'none' }}>Pricing</Link></li>
                                <li><Link to="/faq#" style={{ color: '#b5b5b5', textDecoration: 'none' }}>FAQ</Link></li>
                                <li><Link to="/careers#" style={{ color: '#b5b5b5', textDecoration: 'none' }}>Careers</Link></li>
                            </ul>
                        </div>
                    </Fade>
                    <Fade fade delay={400}>
                        <div style={{ flex: '1 1 200px', marginBottom: '20px' }}>
                            <h4 style={{ fontSize: '16px', fontWeight: '600', color: '#ffffff', marginBottom: '15px' }}>Get In Touch</h4>
                            <ul style={{ listStyle: 'none', padding: 0 }}>
                                <li><i className="ri-mail-fill" style={{ marginRight: '5px' }}></i><a href="mailto:support@stackrhub.com" style={{ color: '#b5b5b5', textDecoration: 'none' }}>support@stackrhub.com</a></li>
                                <li><i className="ri-phone-fill" style={{ marginRight: '5px' }}></i><a href="tel:(819)0000000" style={{ color: '#b5b5b5', textDecoration: 'none' }}>(819) 000 0000</a></li>
                            </ul>
                        </div>
                    </Fade>
                </div>
            </div>
            <div style={{ backgroundColor: '#111', padding: '20px 0', fontSize: '13px', color: '#b5b5b5', textAlign: 'center' }}>
                <div style={{ borderTop: '1px solid #333', margin: '20px 0' }}></div>
                <p>&copy; {new Date().getFullYear()} <Link to="#" style={{ color: '#b5b5b5', textDecoration: 'none' }}>StackrHub</Link>, All Rights Reserved</p>
            </div>
        </footer>
    );
};

export default Footer;
