import React, { useState, useEffect } from 'react';
import { updateProduct, getCompanyWarehouses, uploadProductImages, deleteProductImage,
updateProductImagesOrder, searchProductsTop3, createBundle, updateBundle, deleteBundle, updateProductWarehouse, deleteProductWarehouse } from '../../../api';
import {
  Modal, Tabs, Tab, Box, TextField, Button, Typography, Select, MenuItem, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Paper, Backdrop, Fade, Checkbox, Divider, List, ListItemButton, ListItemText, ListItem, DialogActions
} from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDropzone } from 'react-dropzone';
import MarketIntegrationDialog from '../dialogs/MarketIntegrationDialog';
import { saveMarketIntegration } from '../../../api';
import { useLoading } from '../../../utils/LoadingSpinner';

const EditProductModal = ({ open, handleClose, productData }) => {

  const [formData, setFormData] = useState({
    sku: '',
    name: '',
    price: '',
    sellableQuantity: '',
    reservedQuantity: '',
    description: '',
    warehouses: [],
    images: [],
    productMarkets: [],
    companyId: 0,
    isVariationParent: false,
    isBundle: false,
    bundles: [],
  });

  const [availableWarehouses, setAvailableWarehouses] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedMarket, setSelectedMarket] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const availableMarkets = [/* fetch or pass list of available markets */];
  const { setLoading } = useLoading();
  const [skuSearch, setSkuSearch] = useState('');
  const [skuResults, setSkuResults] = useState([]);
  const [bundleQuantity, setBundleQuantity] = useState('');
  const [bundleItems, setBundleItems] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const newImages = acceptedFiles.map((file) => ({
        id: Math.random().toString(36).substr(2, 9),
        name: file.name,
        file: file,
        url: URL.createObjectURL(file),
        sortIndex: formData.images.length + 1, // Assigning the sort index
      }));
      setFormData((prevState) => ({
        ...prevState,
        images: [...prevState.images, ...newImages]
      }));
    }
  });

  useEffect(() => {
    if (open) {
      getCompanyWarehouses()
        .then(setAvailableWarehouses)
        .catch(() => toast.error('Error fetching warehouses'));
    }
  }, [open]);

  useEffect(() => {
    if (productData) {
      setFormData({
        ...productData,
        sellableQuantity: productData.sellableQuantity,
        reservedQuantity: productData.reservedQuantity,
        isBundle: productData.isBundle,
        isVariationParent: productData.isVariationParent,
        warehouses: productData.warehouses || [],
        images: productData.images || [],
        bundles: productData.bundles || [],
      });
      setBundleItems(productData.bundles || []);
    }
  }, [productData]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleWarehouseFieldChange = (index, field, value) => {
    const updatedWarehouses = [...formData.warehouses];
    updatedWarehouses[index] = {
      ...updatedWarehouses[index],
      [field]: value,
    };
    setFormData((prevState) => ({ ...prevState, warehouses: updatedWarehouses }));
  };

  const handleProductSearch = async (event) => {
    const query = event.target.value;
    setSkuSearch(query);

    if (query.length >= 3) {
      try {
        const results = await searchProductsTop3(query);
        setSkuResults(results);
      } catch (error) {
        console.error('Error searching products:', error);
      }
    } else {
      setSkuResults([]);
    }
  };

  const handleAddToBundle = (selectedSku) => {
    if (!bundleQuantity || isNaN(bundleQuantity) || bundleQuantity <= 0) {
      toast.error('Please enter a valid quantity');
      return;
    }

    const totalAvailable = Math.floor(selectedSku.sellableQuantity / bundleQuantity);

    const newBundleItem = {
      sku: selectedSku.sku,
      name: selectedSku.name,
      qtyBundled: parseInt(bundleQuantity, 10),
      totalAvailable,
    };

    setBundleItems((prevItems) => [...prevItems, newBundleItem]);
    setSkuSearch('');
    setSkuResults([]);
    setBundleQuantity('');
  };

  const handleAddWarehouse = () => {
    setFormData((prevState) => ({
      ...prevState,
      warehouses: [
        ...prevState.warehouses,
        { warehouseId: '', qty: 0, shelf: '', bin: '', aisle: '' },
      ],
    }));
  };


  const handleDeleteWarehouse = async (id, index) => {
    setLoading(true);
    
    const updatedWarehouses = formData.warehouses.filter((_, i) => i !== index);
    setFormData((prevState) => ({ ...prevState, warehouses: updatedWarehouses }));

    try {
      await deleteProductWarehouse(id);
      toast.success('Product updated successfully');
      window.location.reload();
    } catch (error) {
      toast.error('Failed to update product');
      console.error('Error updating product:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleImageSubmit = async () => {
    const productId = productData.id;
    const newImages = formData.images.filter((img) => img.file);

    if (newImages.length === 0) {
      toast.warn("No new images to upload.");
      return;
    }

    const formDataToSend = new FormData();

    newImages.forEach((img) => {
      formDataToSend.append('images', img.file);
    });

    setLoading(true);

    try {
      await uploadProductImages(productId, formDataToSend);
      toast.success('Images uploaded successfully');
    } catch (error) {
      console.error('Error from backend:', error);
      toast.error('There was an error uploading the images');
    } finally {
      setLoading(false);  // Stop loading spinner
    }
  };

const handleDeleteImage = async (productId, image) => {
  if (!image.id || typeof image.id !== 'number') {
    // Remove image from the list without making an API call
    setFormData((prevState) => ({
      ...prevState,
      images: prevState.images.filter((img) => img !== image),
    }));
    return;
  }

  try {
    await deleteProductImage(productId, image.id);
    setFormData((prevState) => ({
      ...prevState,
      images: prevState.images.filter((img) => img.id !== image.id),
    }));
    setLoading(true);
    toast.success('Image deleted successfully');
  } catch (error) {
    toast.error('Failed to delete image');
  } finally {
          setLoading(false);
        }
};


  const handleSortIndexChange = (index, newSortIndex) => {
    const updatedImages = [...formData.images];
    updatedImages[index].sortIndex = Number(newSortIndex);
    setFormData((prevState) => ({
      ...prevState,
      images: updatedImages
    }));
  };

  const onDragEnd = (result) => {
    if (!result.destination) return; // Exit if dropped outside the list

    const reorderedImages = Array.from(formData.images);
    const [movedImage] = reorderedImages.splice(result.source.index, 1);
    reorderedImages.splice(result.destination.index, 0, movedImage);

    // Update the sort index for each image
    const updatedImages = reorderedImages.map((image, index) => ({
      ...image,
      sortIndex: index
    }));

    setFormData((prevState) => ({
      ...prevState,
      images: updatedImages
    }));

    handleUpdateImageOrder(updatedImages);
  };

  const handleUpdateImageOrder = async (images) => {
    const productId = productData.id;
    const imageOrder = images.map((image) => image.id); // Only IDs

    setLoading(true);
    try {
      await updateProductImagesOrder(productId, imageOrder);
      toast.success('Image order updated successfully');
    } catch (error) {
      console.error('Error updating image order:', error);
      toast.error('Failed to update image order');
    } finally {
      setLoading(false);
    }
  };

  const handleMarketClick = (market) => {
    setSelectedMarket(market);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleMarketSave = async (market) => {
      const productId = productData.id;
      setLoading(true)

    try {
      await saveMarketIntegration({ productId, marketId: market.id });
    } catch (error) {
      console.error('Error saving market integration:', error);
    }finally{
      setLoading(false)
    }
  };

  const handleSaveProduct = async () => {
    setLoading(true);

    const productUpdateData = {
      ...formData,
      images: formData.images.map((img) => ({
        id: img.id,
        sortIndex: img.sortIndex,
        imageData: img.imageData || "",
      })),
    };

    try {
      await updateProduct(productData.id, productUpdateData);
      toast.success('Product updated successfully');
    } catch (error) {
      toast.error('Failed to update product');
      console.error('Error updating product:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProductWarehouse = async (id) => {
    setLoading(true);
    const warehouseToUpdate = formData.warehouses.find((warehouse) => warehouse.id === id);
  
    const updatedData = {
      qty: parseInt(warehouseToUpdate.qty),
      shelf: warehouseToUpdate.shelf,
      bin: warehouseToUpdate.bin,
      aisle: warehouseToUpdate.aisle
    };

    try {
      await updateProductWarehouse(id, updatedData);
      toast.success('Product updated successfully');
    } catch (error) {
      toast.error('Failed to update product');
      console.error('Error updating product:', error);
    } finally {
      setLoading(false);
    }

  }

  const handleSaveBundle = async () => {
    if (bundleItems.length === 0) {
      toast.error("No items added to the bundle.");
      return;
    }

    const parentSku = formData.sku;
    const parentId = productData.id;
    const bundleId = bundleItems[0]?.bundleId || null; // Check if a bundleId exists on the first item

    const bundlePayload = bundleItems.map(item => ({
      id: item.id || null,
      bundleId: bundleId,
      parentId: parentId,
      parentSku: parentSku,
      childId: item.childId,
      childSku: item.sku,
      childQty: item.qtyBundled,
    }));

    try {
      if (bundleId) {
        await updateBundle(bundleId, bundlePayload);
        toast.success("Bundle updated successfully.");
      } else {
        await createBundle(parentSku, parentId, bundlePayload);
        toast.success("Bundle created successfully.");
      }
    } catch (error) {
      toast.error("Failed to save bundle.");
    }
  };


  const totalAssignedQty = formData.warehouses.reduce((sum, warehouse) => sum + Number(warehouse.qty), 0);
  const availableQty = formData.sellableQuantity - totalAssignedQty;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="edit-product-modal-title"
      aria-describedby="edit-product-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '1200px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            overflowY: 'auto',
            maxHeight: '90vh',
          }}
        >
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="General" />
            <Tab label="Images" />
            <Tab label="Markets" />
            <Tab label="Orders" />
            <Tab label="Purchasing" />
            <Tab label="Warehousing" />
            {formData.isBundle && <Tab label="Bundles" />}
            {formData.isVariationParent && <Tab label="Variations" />}
          </Tabs>

          {/* General Tab */}
          {activeTab === 0 && (
            <form onSubmit={(e) => { e.preventDefault(); handleSaveProduct(); }}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="SKU"
                name="sku"
                value={formData.sku}
                onChange={(e) => setFormData({ ...formData, sku: e.target.value })}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Price"
                name="price"
                type="number"
                value={formData.price}
                onChange={(e) => setFormData({ ...formData, price: e.target.value })}
              />
              <Box display="flex" gap={2}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Sellable Quantity"
                  name="sellableQuantity"
                  type="number"
                  value={formData.sellableQuantity}
                  onChange={(e) => setFormData({ ...formData, sellableQuantity: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Reserved Quantity"
                  name="reservedQuantity"
                  type="number"
                  value={formData.reservedQuantity}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Description"
                name="description"
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              />
              <Box display="flex" alignItems="center" marginTop="8px">
                <Checkbox
                  checked={formData.isVariationParent}
                  onChange={(e) => setFormData({ ...formData, isVariationParent: e.target.checked })}
                  name="isVariationParent"
                />
                <Typography>Is Variation Parent</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Checkbox
                  checked={formData.isBundle}
                  onChange={(e) => setFormData({ ...formData, isBundle: e.target.checked })}
                  name="isBundle"
                />
                <Typography>Is Bundle</Typography>
              </Box>
              <Button type="submit" variant="contained" color="primary" fullWidth style={{ marginTop: '16px' }}>
                Update Product
              </Button>
            </form>
          )}

          {/* Images Tab */}
          {activeTab === 1 && (
            <Box>
              <div {...getRootProps()} style={{ border: '1px dashed grey', padding: '16px', marginTop: '8px' }}>
                <input {...getInputProps()} />
                <Typography>Drag 'n' drop images here, or click to select files</Typography>
              </div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="images">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {formData.images.map((image, index) => (
                        <Draggable key={image.id} draggableId={String(image.id)} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '10px',
                                ...provided.draggableProps.style
                              }}
                            >
                              <img
                                src={image.url || `data:image/png;base64,${image.imageData}`}
                                alt={image.name || 'Product Image'}
                                style={{ width: '50px', height: '50px', marginRight: '10px' }}
                              />
                              <Typography>{image.name || 'Image'}</Typography>
                              <TextField
                                label="Index"
                                type="number"
                                value={image.sortIndex}
                                style={{ width: '60px', marginLeft: '10px' }}
                                onChange={(e) => handleSortIndexChange(index, e.target.value)}
                              />
                              <Button onClick={() => handleDeleteImage(productData.id, image.id)}>Delete</Button>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <Button variant="contained" color="primary" onClick={handleImageSubmit} style={{ marginTop: '16px' }}>
                Save Images
              </Button>
            </Box>
          )}

          {/* Markets Tab */}
          {activeTab === 2 && (
                  <Box display="flex">
                    <List component="nav">
                      {formData.productMarkets.map((market) => (
                        <ListItemButton
                          key={market.id}
                          selected={selectedMarket?.id === market.id}
                          onClick={() => handleMarketClick(market)}
                        >
                          <ListItemText primary={market.nickname} secondary={market.marketType} />
                        </ListItemButton>
                      ))}
                      <ListItem>
                        <Button variant="contained" color="primary" onClick={handleDialogOpen}>
                          Connect New Market
                        </Button>
                      </ListItem>
                    </List>
                    <Divider orientation="vertical" flexItem />
                    <Box flex={1} padding="16px">
                      {selectedMarket ? (
                        <div>
                          <Typography variant="h6">{selectedMarket.nickname}</Typography>
                          <Typography variant="body2">Market ID: {selectedMarket.marketId}</Typography>
                          <Typography variant="body2">Market Type: {selectedMarket.marketType}</Typography>
                        </div>
                      ) : (
                        <Typography variant="body1">Select a market to see details</Typography>
                      )}
                    </Box>
            </Box>
          )}

          {/* Orders Tab */}
          {activeTab === 3 && (
            <div>
              <Typography variant="body1">Orders content</Typography>
            </div>
          )}

          {/* Purchasing Tab */}
          {activeTab === 4 && (
            <div>
              <Typography variant="body1">Purchasing content</Typography>
            </div>
          )}

          {/* Warehousing Tab */}
          {activeTab === 5 && (
            <Box>
              <Typography variant="h6" gutterBottom>
                Manage Warehousing
              </Typography>

              <Typography variant="body2" color="textSecondary" gutterBottom>
                Available quantity: {availableQty}
              </Typography>

              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Warehouse</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Shelf</TableCell>
                      <TableCell>Bin</TableCell>
                      <TableCell>Aisle</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formData.warehouses.map((warehouse, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Select
                            value={warehouse.warehouseId}
                            onChange={(e) => handleWarehouseFieldChange(index, 'warehouseId', e.target.value)}
                            fullWidth
                          >
                            {availableWarehouses.map((wh) => (
                              <MenuItem key={wh.id} value={wh.id}>
                                {wh.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            value={warehouse.qty}
                            onChange={(e) => handleWarehouseFieldChange(index, 'qty', e.target.value)}
                            fullWidth
                            error={totalAssignedQty > formData.sellableQuantity}
                            helperText={totalAssignedQty > formData.sellableQuantity && 'Quantity exceeds available stock'}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={warehouse.shelf}
                            onChange={(e) => handleWarehouseFieldChange(index, 'shelf', e.target.value)}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={warehouse.bin}
                            onChange={(e) => handleWarehouseFieldChange(index, 'bin', e.target.value)}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={warehouse.aisle}
                            onChange={(e) => handleWarehouseFieldChange(index, 'aisle', e.target.value)}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <DialogActions>
                            <Button onClick={() => handleDeleteWarehouse(warehouse.id, index)} color="primary">Delete</Button>
                            <Button onClick={() => handleUpdateProductWarehouse(warehouse.id)} color="primary">Save</Button>
                          </DialogActions>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Button onClick={handleAddWarehouse} variant="contained" color="primary" style={{ marginTop: '16px' }}>
                Add Warehouse Location
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveProduct}
                style={{ marginTop: '16px', float: 'right' }}
                disabled={totalAssignedQty > formData.sellableQuantity}
              >
                Save Product
              </Button>
            </Box>
          )}

          {/* Bundle Tab */}
          {activeTab === 6 && (
            <Box mt={2}>
              <Typography variant="h6">Create Bundle</Typography>

              {/* SKU Search Input */}
              <TextField
                label="Search SKU"
                value={skuSearch}
                onChange={async (e) => {
                  const query = e.target.value;
                  setSkuSearch(query);
                  if (query.length >= 3) {
                    try {
                      const results = await searchProductsTop3(query);
                      setSkuResults(results);
                    } catch (error) {
                      console.error('Error searching products:', error);
                    }
                  } else {
                    setSkuResults([]);
                  }
                }}
                fullWidth
                margin="normal"
              />

              {/* Display SKU Search Results */}
              {skuResults.length > 0 && (
                <Box mt={2}>
                  <Typography variant="subtitle1">Select Product</Typography>
                  <List>
                    {skuResults.map((product) => (
                      <ListItem
                        key={product.id}
                        button
                        onClick={() => {
                          setBundleItems((prev) => [
                            ...prev,
                            {
                              sku: product.sku,
                              name: product.name,
                              qtyBundled: 1, // Default quantity, can be adjusted
                              totalAvailable: Math.floor(product.sellableQuantity / 1), // Default qty
                              childId: product.id,
                            },
                          ]);
                          setSkuSearch('');
                          setSkuResults([]);
                        }}
                      >
                        <ListItemText primary={product.sku} secondary={product.name} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}

              {/* Bundle Item List */}
              <Box mt={2}>
                <Typography variant="h6">Bundle Items</Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>SKU</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Quantity in Bundle</TableCell>
                        <TableCell>Total Available</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bundleItems.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.sku}</TableCell>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>
                            <TextField
                              type="number"
                              value={item.qtyBundled}
                              onChange={(e) => {
                                const newQty = parseInt(e.target.value, 10) || 1;
                                setBundleItems((prev) =>
                                  prev.map((itm, idx) =>
                                    idx === index
                                      ? {
                                          ...itm,
                                          qtyBundled: newQty,
                                          totalAvailable: Math.floor(
                                            formData.sellableQuantity / newQty
                                          ),
                                        }
                                      : itm
                                  )
                                );
                              }}
                              inputProps={{ min: 1 }}
                            />
                          </TableCell>
                          <TableCell>{item.totalAvailable}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              {/* Save Bundle Button */}
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  const parentSku = formData.sku;
                  const parentId = productData.id;
                  const bundleId = bundleItems[0]?.bundleId || null;

                  const bundlePayload = bundleItems.map((item) => ({
                    id: item.id || null,
                    bundleId: bundleId,
                    parentId: parentId,
                    parentSku: parentSku,
                    childId: item.childId,
                    childSku: item.sku,
                    childQty: item.qtyBundled,
                  }));

                  try {
                    if (bundleId) {
                      await updateBundle(bundleId, bundlePayload);
                      toast.success("Bundle updated successfully.");
                    } else {
                      await createBundle(parentSku, parentId, bundlePayload);
                      toast.success("Bundle created successfully.");
                    }
                  } catch (error) {
                    toast.error("Failed to save bundle.");
                  }
                }}
                style={{ marginTop: '16px' }}
              >
                Save Bundle
              </Button>
            </Box>
          )}

          {/* Variations Tab */}
          {activeTab === 7 && (
            <div>
              <Typography variant="body1">Variation content</Typography>
            </div>
          )}
          {/* Market Integration Dialog */}
             <MarketIntegrationDialog
               open={isDialogOpen}
               onClose={handleDialogClose}
              availableMarkets={availableMarkets}
              onSave={handleMarketSave}
         />

        </Box>
      </Fade>

    </Modal>
  );
};

export default EditProductModal;
