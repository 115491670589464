import React, { useState, useEffect } from 'react';
import { Modal, Autocomplete, Tabs, Tab, Box, TextField, Typography, Button, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Checkbox, IconButton, Menu, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { updateOrder, searchProductsTop3 } from '../../../api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLoading } from '../../../utils/LoadingSpinner';

const EditOrderModal = ({ handleClose, orderData }) => {
  const [formData, setFormData] = useState({
    id: '',
    companyId: 0,
    market: 'LOCAL',
    marketIntegrationId: '',
    orderNumber: '',
    marketOrderNumber: '',
    shippingStatus: 'UNSHIPPED',
    paymentStatus: 'UNPAID',
    orderStatus: 'OPEN',
    orderDate: '',
    customerName: '',
    orderItems: [],
    totalQty: 0,
    totalPrice: 0.0,
    address: {
      street: '',
      street2: '',
      city: '',
      state: '',
      postalCode: '',
      country: ''
    }
  });

  const [activeTab, setActiveTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { setLoading } = useLoading();

  useEffect(() => {
    if (orderData) {
      setFormData(orderData);
    }
  }, [orderData]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddressChange = (e, addressType) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [addressType]: {
        ...prevState[addressType],
        [name]: value,
      }
    }));
  };

  const handleItemChange = (index, e) => {
    const { name, value } = e.target;
    const newOrderItems = [...formData.orderItems];
    newOrderItems[index] = { ...newOrderItems[index], [name]: value };
    setFormData((prevState) => ({
      ...prevState,
      orderItems: newOrderItems
    }));
  };

  const addItem = () => {
    setFormData((prevState) => ({
      ...prevState,
      orderItems: [...prevState.orderItems, { id: 'UNKNOWN', sku: '', qty: '', price: '' }]
    }));
  };

  const handleProductSearch = async (event) => {
    const query = event.target.value;
    if (query.length >= 3) {
      setLoading(true)
      try {
        const results = await searchProductsTop3(query);
        setSearchResults(results);
      } catch (error) {
        console.error('Error searching products:', error);
      } finally {
        setLoading(false)
      }
    } else {
      setSearchResults([]);
    } 
  };

  const handleMenuClick = (event, itemId) => {
    setAnchorEl(event.currentTarget);
    setSelectedItemId(itemId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLink = (productId) => {
    const updatedItems = formData.orderItems.map(item => {
      if (item.id === selectedItemId) {
        return { ...item, id: productId };
      }
      return item;
    });
    setFormData({ ...formData, orderItems: updatedItems });
    handleMenuClose();
  };
const handleAddProduct = () => {
  if (selectedProduct) {
    const newProduct = {
      id: selectedProduct.id,
      sku: selectedProduct.sku,
      qty: 1,
      price: selectedProduct.price
    };
    setFormData((prevState) => ({
      ...prevState,
      orderItems: [...prevState.orderItems, newProduct]
    }));
    setSelectedProduct(null);
  } else {
    toast.error('Please select a product to add.');
  }
};

const handleSave = async () => {
  setLoading(true)
  try {
    const updatedOrderData = {
      ...formData,
      orderItems: formData.orderItems.map((item) => ({
        productId: item.id,
        sku: item.sku,
        qty: parseInt(item.qty),
        price: item.price
      })),
      totalQty: formData.orderItems.reduce((sum, item) => sum + parseInt(item.qty), 0),
      totalPrice: formData.orderItems.reduce((sum, item) => sum + parseFloat(item.price), 0),
    };

    await updateOrder(formData.id, updatedOrderData);
    toast.success('Order updated successfully');
    handleClose();
    window.location.reload()
  } catch (error) {
    toast.error('Failed to update order');
  } finally {
    setLoading(false)
  }
};

  return (
    <Modal
      open
      onClose={handleClose}
      aria-labelledby="edit-order-modal-title"
      aria-describedby="edit-order-modal-description"
    >
      <Box sx={{ ...style, width: 800 }}>
        <Typography id="edit-order-modal-title" variant="h6" component="h2">
          Edit Order
        </Typography>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="General" />
          <Tab label="Items" />
          <Tab label="Payment Info" />
          <Tab label="Customer Info" />
        </Tabs>
        {activeTab === 0 && (
          <Box component="form" noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Order ID"
              name="id"
              value={formData.id}
              disabled
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Company ID"
              name="companyId"
              value={formData.companyId}
              disabled
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Market"
              name="market"
              value={formData.market}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Order Number"
              name="orderNumber"
              value={formData.orderNumber}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Market Order Number"
              name="marketOrderNumber"
              value={formData.marketOrderNumber}
              onChange={handleChange}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Shipping Status</InputLabel>
              <Select
                value={formData.shippingStatus}
                onChange={handleChange}
                name="shippingStatus"
              >
                <MenuItem value="UNSHIPPED">UNSHIPPED</MenuItem>
                <MenuItem value="SHIPPED">SHIPPED</MenuItem>
                <MenuItem value="PARTIAL_SHIP">PARTIAL_SHIP</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Payment Status</InputLabel>
              <Select
                value={formData.paymentStatus}
                onChange={handleChange}
                name="paymentStatus"
              >
                <MenuItem value="PAID">PAID</MenuItem>
                <MenuItem value="UNPAID">UNPAID</MenuItem>
                <MenuItem value="FULL_REFUND">FULL_REFUND</MenuItem>
                <MenuItem value="PARTIAL_REFUND">PARTIAL_REFUND</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Order Status</InputLabel>
              <Select
                value={formData.orderStatus}
                onChange={handleChange}
                name="orderStatus"
              >
                <MenuItem value="OPEN">OPEN</MenuItem>
                <MenuItem value="CLOSED">CLOSED</MenuItem>
                <MenuItem value="CANCELLED">CANCELLED</MenuItem>
              </Select>
            </FormControl>
            <Button onClick={handleSave} variant="contained" color="primary" fullWidth style={{ marginTop: '16px' }}>
              Save
            </Button>
          </Box>
        )}
        {activeTab === 1 && (
          <Box>
        <Box flex={1} marginRight={2}>
           <Autocomplete
             options={searchResults}
             getOptionLabel={(option) => `${option.id} - ${option.sku} - ${option.name}`}
             value={selectedProduct}
             onInputChange={handleProductSearch}
             onChange={(event, newValue) => setSelectedProduct(newValue)}
             renderInput={(params) => <TextField {...params} label="Search Product" margin="normal" />}
           />

              <Button onClick={handleAddProduct} variant="contained" color="primary" style={{ marginLeft: '8px' }}>
                Add Product
              </Button>
            </Box>
            {searchResults.length > 0 && (
              <Box>
                {searchResults.map((product) => (
                  <Box key={product.id} display="flex" alignItems="center" justifyContent="space-between" marginBottom="8px">
                    <Typography>{product.sku} - {product.name}</Typography>
                    <Button onClick={() => handleLink(product.id)} variant="contained" color="primary">
                      Add
                    </Button>
                  </Box>
                ))}
              </Box>
            )}
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox />
                    </TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>SKU</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Options</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formData.orderItems.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell padding="checkbox">
                        <Checkbox />
                      </TableCell>
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{item.sku}</TableCell>
                      <TableCell>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          name="qty"
                          value={item.qty}
                          onChange={(e) => handleItemChange(index, e)}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          name="price"
                          value={item.price}
                          onChange={(e) => handleItemChange(index, e)}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={(event) => handleMenuClick(event, item.id)}>
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl) && selectedItemId === item.id}
                          onClose={handleMenuClose}
                        >
                          {item.id === 'UNKNOWN' && <MenuItem onClick={() => handleLink(item.id)}>Link</MenuItem>}
                          <MenuItem>Edit</MenuItem>
                          <MenuItem onClick={() => {
                            const newOrderItems = formData.orderItems.filter((_, i) => i !== index);
                            setFormData({ ...formData, orderItems: newOrderItems });
                          }}>Delete</MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button onClick={handleSave} variant="contained" color="primary" fullWidth style={{ marginTop: '16px' }}>
              Update Order Items
            </Button>
          </Box>
        )}
        {activeTab === 2 && (
          <Box>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Total Price"
              name="totalPrice"
              value={formData.totalPrice}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Payment Date"
              name="paymentDate"
              value={formData.paymentDate}
              onChange={handleChange}
            />
            <Button onClick={handleSave} variant="contained" color="primary" fullWidth style={{ marginTop: '16px' }}>
              Save
            </Button>
          </Box>
        )}
        {activeTab === 3 && (
          <Box>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Customer Name"
              name="customerName"
              value={formData.customerName}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Street"
              name="street"
              value={formData.address.street}
              onChange={(e) => handleAddressChange(e, 'address')}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Street 2"
              name="street2"
              value={formData.address.street2}
              onChange={(e) => handleAddressChange(e, 'address')}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="City"
              name="city"
              value={formData.address.city}
              onChange={(e) => handleAddressChange(e, 'address')}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="State"
              name="state"
              value={formData.address.state}
              onChange={(e) => handleAddressChange(e, 'address')}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Postal Code"
              name="postalCode"
              value={formData.address.postalCode}
              onChange={(e) => handleAddressChange(e, 'address')}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Country"
              name="country"
              value={formData.address.country}
              onChange={(e) => handleAddressChange(e, 'address')}
            />
            <Button onClick={handleSave} variant="contained" color="primary" fullWidth style={{ marginTop: '16px' }}>
              Save
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default EditOrderModal;
