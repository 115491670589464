import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Chip } from '@mui/material';
import { fetchVariationOptionSets, createVariationOptionSet, deleteVariationOptionSet } from '../../../api';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLoading } from '../../../utils/LoadingSpinner';


const ManageVariationsModal = ({ open, handleClose }) => {
  const [optionSets, setOptionSets] = useState([]);
  const [newOptionSetName, setNewOptionSetName] = useState('');
  const [newOptions, setNewOptions] = useState([{ name: '', values: [] }]);
  const [openDialog, setOpenDialog] = useState(false);
  const { setLoading } = useLoading();

  useEffect(() => {
    if (open) {
      loadOptionSets();
    }
  }, [open]);

  const loadOptionSets = async () => {
    setLoading(true)

    try {
      const data = await fetchVariationOptionSets();
      setOptionSets(data);
    } catch (error) {
      console.error('Error loading variation option sets:', error);
    }finally{
      setLoading(false)
    }
  };

  const handleOpenDialog = () => {
    setNewOptionSetName('');
    setNewOptions([{ name: '', values: [] }]);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const handleAddOption = () => {
    setNewOptions([...newOptions, { name: '', values: [] }]);
  };

  const handleOptionNameChange = (index, name) => {
    const updatedOptions = [...newOptions];
    updatedOptions[index].name = name;
    setNewOptions(updatedOptions);
  };

  const handleOptionValueChange = (index, value) => {
    const updatedOptions = [...newOptions];
    updatedOptions[index].values = value.split(',').map((val) => val.trim());
    setNewOptions(updatedOptions);
  };

  const handleSaveOptionSet = async () => {
    setLoading(true)

    const newOptionSet = {
      name: newOptionSetName,
      options: newOptions,
    };

    try {
      await createVariationOptionSet(newOptionSet);
      loadOptionSets();
      handleCloseDialog();
    } catch (error) {
      console.error('Error creating variation option set:', error);
    }finally{
      setLoading(false)
    }
  };

  const handleDeleteOptionSet = async (optionSetId) => {
    setLoading(true)
    try {
      await deleteVariationOptionSet(optionSetId);
      loadOptionSets();
    } catch (error) {
      console.error('Error deleting variation option set:', error);
    }finally{
      setLoading(false)
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ width: 600, bgcolor: 'background.paper', margin: 'auto', mt: 4, p: 3, borderRadius: 1 }}>
        <Typography variant="h6" gutterBottom>Manage Variations</Typography>
        <Button variant="contained" color="primary" onClick={handleOpenDialog} sx={{ mb: 2 }}>
          Add New Option Set
        </Button>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Options</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {optionSets.map((set) => (
                <TableRow key={set.id}>
                  <TableCell>{set.id}</TableCell>
                  <TableCell>{set.name}</TableCell>
                  <TableCell>
                    {set.options.map((option) => (
                      <div key={option.name}>
                        <strong>{option.name}:</strong> {option.values.join(', ')}
                      </div>
                    ))}
                  </TableCell>
                  <TableCell>
                    <IconButton color="secondary" onClick={() => handleDeleteOptionSet(set.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Add New Option Set</DialogTitle>
          <DialogContent>
            <TextField
              label="Option Set Name"
              fullWidth
              margin="normal"
              value={newOptionSetName}
              onChange={(e) => setNewOptionSetName(e.target.value)}
            />
            {newOptions.map((option, index) => (
              <Box key={index} sx={{ mt: 2 }}>
                <TextField
                  label="Option Name"
                  fullWidth
                  value={option.name}
                  onChange={(e) => handleOptionNameChange(index, e.target.value)}
                />
                <TextField
                  label="Option Values (comma-separated)"
                  fullWidth
                  value={option.values.join(', ')}
                  onChange={(e) => handleOptionValueChange(index, e.target.value)}
                  helperText="Press comma to separate values"
                />
              </Box>
            ))}
            <Button onClick={handleAddOption} variant="outlined" color="primary" sx={{ mt: 2 }}>
              Add Another Option
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={handleSaveOptionSet} color="primary" variant="contained">
              Save Option Set
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Modal>
  );
};

export default ManageVariationsModal;
