import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem } from '@mui/material';
import { toast } from 'react-toastify';

const AddAccountingIntegrationModal = ({ open, onClose }) => {
  const [market, setMarket] = useState('');
  const [nickname, setNickname] = useState('');

  const handleSave = async () => {
    const companyId = localStorage.getItem('companyId');

    try {
      let response;
      if (market === 'Quickbooks') {
//        response = await getEbayAuthUrl({ nickname, companyId });
      } else if (market === 'Quickbooks-Online') {
//        response = await getAmazonAuthUrl({ nickname, companyId });
      }
//      if (response?.authUrl) {
//        window.location.href = response.authUrl;
//      } else {
//        throw new Error('Invalid response from server');
//      }
    } catch (error) {
      toast.error(`Error getting ${market} auth URL`);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Add Accounting Integration</DialogTitle>
      <DialogContent>
        <TextField
          select
          fullWidth
          value={market}
          onChange={(e) => setMarket(e.target.value)}
          displayEmpty
        >
          <MenuItem value="" disabled>Select Market</MenuItem>
          <MenuItem value="Quickbooks">Quickbooks</MenuItem>
          <MenuItem value="Quickbooks-Online">Quickbooks Online</MenuItem>
        </TextField>
        <TextField
          fullWidth
          margin="normal"
          label="Nickname"
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={handleSave} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAccountingIntegrationModal;
