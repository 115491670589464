import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Typography,
  Switch,
  Link,
  Button,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { getIntegrationsByCompanyId, deleteIntegration } from '../../api';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import MarketTemplatesModal from './modals/MarketTemplatesModal';
import OtherIntegrationWizardModal from './modals/OtherIntegrationWizardModal';
import AddMarketIntegrationModal from './modals/AddMarketIntegrationModal';
import EditMarketIntegrationModal from './modals/EditMarketIntegrationModal';
import AddShippingIntegrationModal from './modals/AddShippingIntegrationModal';
import AddAccountingIntegrationModal from './modals/AddAccountingIntegrationModal';
import { useLoading } from '../../utils/LoadingSpinner';

const IntegrationsList = ({ filters }) => {
  const theme = useTheme();
  const { setLoading } = useLoading();
  const [integrations, setIntegrations] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIntegrationId, setSelectedIntegrationId] = useState(null);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [openFTPModal, setOpenFTPModal] = useState(false);
  const [openAddMarketModal, setOpenAddMarketModal] = useState(false);
  const [openAddShippingModal, setOpenAddShippingModal] = useState(false);
  const [openAddAccountingModal, setOpenAddAccountingModal] = useState(false);
  const [openEditMarketIntegrationModal, setOpenEditMarketIntegrationModal] = useState(false);

  useEffect(() => {
    const fetchAndSetIntegrations = async () => {
      try {
        const response = await getIntegrationsByCompanyId(filters);
        setIntegrations(response);
      } catch (error) {
        toast.error('Failed to fetch integrations. Please try again later.');
      }
    };
    fetchAndSetIntegrations();
  }, [filters]);

  const handleMenuClick = (event, integrationId) => {
    setAnchorEl(event.currentTarget);
    setSelectedIntegrationId(integrationId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleToggle = (integrationId, field) => {
    toast.info(`Toggle ${field} functionality is under development.`);
  };

  const handleEdit = () => {
    toast.info('Edit functionality is under development.');
    handleMenuClose();
  };

  const handleDelete = async (id) => {

    setLoading(true);
    try {
      await deleteIntegration(id);
      toast.success('Integration deleted successfully');
      window.location.reload();
    } catch (error) {
      toast.error('Failed to delete integration');
      console.error('Error deleting integration:', error);
    } finally {
      setLoading(false);
    }

    handleMenuClose();
  };

  const openFTPIntegrationModal = () => {
    setOpenFTPModal(true);
  };
  const handleAddMarketClick = () => {
    setOpenAddMarketModal(true);
  };

  const handleCloseAddMarketModal = () => {
    setOpenAddMarketModal(false);
  };

  const handleAddShippingClick = () => {
    setOpenAddShippingModal(true);
  };

  const handleCloseAddShippingModal = () => {
    setOpenAddShippingModal(false);
  };

  const handleAddAccountingClick = () => {
    setOpenAddAccountingModal(true);
  };

  const handleCloseAddAccountingModal = () => {
    setOpenAddAccountingModal(false);
  };

  const handleEditMarketIntegrationClick = () => {
    setOpenEditMarketIntegrationModal(true);
  };

  const handleCloseEditMarketIntegrationModal = () => {
    setOpenEditMarketIntegrationModal(false);
  };


  const renderTableHeader = (title, onAddClick, showTemplates = false) => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: theme.palette.primary.main, color: 'white', padding: '8px 16px' }}>
      <Typography variant="h6">{title}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {showTemplates && (
          <Link href="#" onClick={() => setOpenTemplateModal(true)} sx={{ color: 'white', marginRight: '8px' }}>
            Templates
          </Link>
        )}
        <IconButton onClick={onAddClick} sx={{ color: 'white' }}>
          <AddIcon />
        </IconButton>
      </Box>
    </Box>
  );

  const renderOtherTable = () => (
    <Box sx={{ marginBottom: '24px' }}>
      {renderTableHeader('Other', openFTPIntegrationModal)}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nickname</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Custom Mapping</TableCell>
              <TableCell>Schedule</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {integrations
              .filter(integration => integration.type === 'OTHER')
              .map(integration => (
                <TableRow key={integration.id}>
                  <TableCell>{integration.id}</TableCell>
                  <TableCell>{integration.nickname}</TableCell>
                  <TableCell>{integration.type}</TableCell>
                  <TableCell>{integration.customMapping ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{integration.schedule}</TableCell>
                  <TableCell>{integration.notes}</TableCell>
                  <TableCell>
                    <IconButton onClick={(event) => handleMenuClick(event, integration.id)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl) && selectedIntegrationId === integration.id}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleEdit}>
                        <EditIcon fontSize="small" /> Edit
                      </MenuItem>
                      <MenuItem onClick={() => handleDelete(integration.id)}>
                        <DeleteIcon fontSize="small" /> Delete
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const renderMarketTable = (title, filterType) => (
    <Box sx={{ marginBottom: '24px' }}>
      {renderTableHeader(title, handleAddMarketClick)} {/* Add the click handler */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nickname</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {integrations
              .filter(integration => integration.type === filterType)
              .map(integration => (
                <TableRow key={integration.id}>
                  <TableCell>{integration.id}</TableCell>
                  <TableCell>{integration.nickname}</TableCell>
                  <TableCell>{integration.type}</TableCell>
                  <TableCell>{integration.createdAt.substring(0,10)}</TableCell>
                  <TableCell>{integration.notes}</TableCell>
                  <TableCell>
                    <IconButton onClick={(event) => handleMenuClick(event, integration.id)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl) && selectedIntegrationId === integration.id}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleEdit}>Edit</MenuItem>
                      <MenuItem onClick={() => handleDelete(integration.id)}>Delete</MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

const renderShippingTable = (title, filterType, onAddClick) => (
  <Box sx={{ marginBottom: '24px' }}>
    {renderTableHeader(title, handleAddShippingClick)} {/* Use the shipping modal handler */}
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Nickname</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Created Date</TableCell>
            <TableCell>Notes</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {integrations
            .filter(integration => integration.type === filterType)
            .map(integration => (
              <TableRow key={integration.id}>
                <TableCell>{integration.id}</TableCell>
                <TableCell>{integration.nickname}</TableCell>
                <TableCell>{integration.type}</TableCell>
                <TableCell>{integration.createdDate}</TableCell>
                <TableCell>{integration.notes}</TableCell>
                <TableCell>
                  <IconButton onClick={(event) => handleMenuClick(event, integration.id)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && selectedIntegrationId === integration.id}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleEdit}>
                      <EditIcon fontSize="small" /> Edit
                    </MenuItem>
                    <MenuItem onClick={() => handleDelete(integration.id)}>
                      <DeleteIcon fontSize="small" /> Delete
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
);

  const renderAccountingTable = (title, filterType, onAddClick) => (
    <Box sx={{ marginBottom: '24px' }}>
    {renderTableHeader(title, handleAddAccountingClick)} {/* Use the accounting modal handler */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nickname</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {integrations
              .filter(integration => integration.type === filterType)
              .map(integration => (
                <TableRow key={integration.id}>
                  <TableCell>{integration.id}</TableCell>
                  <TableCell>{integration.nickname}</TableCell>
                  <TableCell>{integration.type}</TableCell>
                  <TableCell>{integration.createdDate}</TableCell>
                  <TableCell>{integration.notes}</TableCell>
                  <TableCell>
                    <IconButton onClick={(event) => handleMenuClick(event, integration.id)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl) && selectedIntegrationId === integration.id}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleEdit}>
                        <EditIcon fontSize="small" /> Edit
                      </MenuItem>
                      <MenuItem onClick={() => handleDelete(integration.id)}>
                        <DeleteIcon fontSize="small" /> Delete
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  return (
    <Box>
      {renderMarketTable('Markets', 'MARKET')} {/* Market table rendering */}
      {renderShippingTable('Shipping', 'SHIPPING')} {/* Shipping table rendering */}
      {renderAccountingTable('Accounting', 'ACCOUNTING')} {/* Accounting table rendering */}
      {renderOtherTable()} {/* Render the Other table with FTP integration */}

      <MarketTemplatesModal open={openTemplateModal} handleClose={() => setOpenTemplateModal(false)} />

      {openFTPModal && (
        <OtherIntegrationWizardModal
          open={openFTPModal}
          onClose={() => setOpenFTPModal(false)}
        />
      )}
      <AddMarketIntegrationModal
        open={openAddMarketModal}
        onClose={handleCloseAddMarketModal}
      />
      <EditMarketIntegrationModal
        open={openEditMarketIntegrationModal}
        onClose={handleCloseEditMarketIntegrationModal}
      />
      <AddShippingIntegrationModal
        open={openAddShippingModal}
        onClose={handleCloseAddShippingModal}
      />

      <AddAccountingIntegrationModal
        open={openAddAccountingModal}
        onClose={handleCloseAddAccountingModal}
      />

    </Box>
  );
};

export default IntegrationsList;
